import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import "./styles.scss";
import CardAdmin from "../../components/Card/CardAdmin";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";

import {
  fetchSubscription,
  fetchPostSubscription,
  fetchMerchantSubscriptions,
} from "../../redux/actions/product";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalCard from "../../components/ModalCard/ModalCard";
import swal from "sweetalert";
import dayjs from "dayjs";

const Subscription = (props) => {
  const [subs, setSubs] = useState([]);
  const [allSubs, setAllSubs] = useState([]);
  const [form, setForm] = useState({});
  const [modal, setModal] = useState(false);
  const [check, setCheck] = useState(false);
  const toggle = () => setModal(!modal);

  function createSubs(e) {
    e.preventDefault();
    setCheck(true);
    props.fetchPostSubscription(form);
  }

  useEffect(() => {
    props.fetchSubscription();
    props.fetchMerchantSubscriptions();
  }, []);

  useEffect(() => {
    if (props.subscription.subscription.data) {
      setSubs(props.subscription.subscription.data.plan);
    }
    if (check) {
      swal("Plan created", "", "success");
      setCheck(false);
    }
  }, [props.subscription.subscription]);

  useEffect(() => {
    if (props.subscriptions.subscriptions.data) {
      setAllSubs(props.subscriptions.subscriptions.data.history);
    }
  }, [props.subscriptions.subscriptions]);

  return (
    <Layout>
      <section className="dash-section ">
        <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>

        <div className="row dash-info-card">
          {subs.map((item, i) => {
            return (
              <div className="col-md-3" key={i}>
                <CardAdmin>
                  <div className="d-flex justify-content-between p-0 mb-3">
                    <p className="dash-info-title">{item.plan}</p>
                    <i class="fa fa-cog" aria-hidden="true"></i>
                  </div>
                  <p className="dash-info-digit">
                    {item.paid ? item.price : "FREE"}
                  </p>
                </CardAdmin>
              </div>
            );
          })}

          <div className="col-md-3">
            <CardAdmin>
              <div
                className="d-flex justify-content-between flex-column text-center p-0 m-0"
                onClick={toggle}
              >
                <p className="dash-info-title mb-4">Create new plan</p>
                <i class="fa fa-plus" aria-hidden="true"></i>
              </div>
            </CardAdmin>
          </div>
        </div>

        <div className="row dash-card-info-3">
          <div className="col-md-12 ">
            <div className="d-flex justify-content-between">
              <p>All subscribers</p>
              <span>
                Sort by Type <i class="fa fa-caret-down" aria-hidden="true"></i>
              </span>
            </div>
            <table class="table table-borderless table-hover table-striped table-responsive-sm">
              <thead>
                <tr>
                  <th>Store ID</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Expires</th>
                  <th>Active on</th>
                </tr>
              </thead>
              <tbody>
                {(allSubs || []).map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.store_id}</td>
                      <td>{item.subscription.plan}</td>
                      <td>{item.subscription.price}</td>
                      <td>
                        {item.paid ? (
                          <span className="badge badge-success">
                            {item.status}
                          </span>
                        ) : (
                          <span className="badge badge-danger">
                            {item.status}
                          </span>
                        )}
                      </td>
                      <td>{item.subscription.validity}</td>
                      <td>
                        {dayjs(item.subscription_date).format("YYYY MM DD")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <ModalCard title="Subscription" toggle={toggle} modal={modal}>
          <form className="form">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="enter plan"
                onChange={(e) => setForm({ ...form, name: e.target.value })}
              />
            </div>
            <div className="form-group">
              <input
                type="number"
                className="form-control"
                placeholder="enter amount"
                onChange={(e) => setForm({ ...form, amount: e.target.value })}
              />
            </div>
            <div className="form-group">
              <textarea
                type="text"
                className="form-control"
                placeholder="enter features"
                onChange={(e) => setForm({ ...form, features: e.target.value })}
              ></textarea>
            </div>
            <div className="form-group">
              <input
                type="number"
                className="form-control"
                placeholder="enter durration"
                onChange={(e) => setForm({ ...form, name: e.target.value })}
              />
            </div>
            <button className="btn btn-primary" onClick={createSubs}>
              create
            </button>
          </form>
        </ModalCard>
      </section>
    </Layout>
  );
};

Subscription.propTypes = {
  subscription: PropTypes.object.isRequired,
  fetchSubscription: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  subscription: state.subscription,
  subscriptions: state.subscriptions,
});

const pageActions = {
  fetchSubscription,
  fetchPostSubscription,
  fetchMerchantSubscriptions,
};

export default connect(mapStateToProps, pageActions)(Subscription);
