import React from "react";
import "./styles.scss";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalCard = (props) => {
  const { title, toggle, modal } = props;
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{props.children}</ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export default ModalCard;
