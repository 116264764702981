import React, { useState, useEffect } from "react";
import logo from "../../images/logo.svg";
import "./styles.scss";
import { Link, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchUserEmailLogin } from "../../redux/actions/authentication";

import isEmpty from "../../utils/isEmpty";

const Login = (props) => {
  let history = useHistory();

  const [form, setForm] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({});
  const [errors, setErrors] = useState({
    message: "",
    code: "",
    yesError: false,
  });
  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const userData = {
      email: form.email,
      password: form.password,
      store_id: "1585139305",
    };
    props.fetchUserEmailLogin(userData);
  }
  useEffect(() => {
    setLoading(false);

    if (!isEmpty(props.user.user)) {
      const { user } = props.user;
      if (!user.hasError) {
        setState(user.data);
        history.push("/dashboard");
        setErrors({ ...errors, yesError: false });
        setForm({ ...form, email: "", password: "" });
      }
    } else {
      if (props.user.errors.errors) {
        setErrors({ ...errors, ...props.user.errors.errors, yesError: true });
        setForm({ ...form, email: "", password: "" });
      }
    }
  }, [props.user]);

  return (
    <div className="logbac">
      <section className="login-sec">
        <div className="login-card">
          <div className="login-header pb-5">
            <img src={logo} alt="logo" width="100" height="" />
          </div>
          <div>
            <form className="text-center" onSubmit={handleSubmit}>
              {errors.yesError && (
                <div className="alert alert-danger">{errors.message}</div>
              )}
              {!isEmpty(state.user) && (
                <div className="alert alert-success">
                  {props.user.user.message}
                </div>
              )}
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  name="email"
                  className="form-control login-input"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  autoFocus
                  required
                  value={form.email}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input
                  type="password"
                  name="password"
                  className="form-control login-input"
                  id="exampleInputPassword1"
                  required
                  value={form.password}
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                />
              </div>

              <div className="d-flex justify-content-between pb-3 pas">
                <label className="input-log">
                  <input type="checkbox" id="checkbox2" checked /> remember me
                </label>
                <br /> <Link className="input-log">forgot password</Link>
              </div>

              <button
                type="submit"
                className="btn login-btn text-white"
                disabled={loading}
              >
                {loading ? "Loading..." : "Log In"}
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

Login.propTypes = {
  user: PropTypes.object.isRequired,
  fetchUserEmailLogin: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.login,
});

const pageActions = { fetchUserEmailLogin };

export default connect(mapStateToProps, pageActions)(Login);
