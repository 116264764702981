import React from "react";
import Layout from "../../components/Layout/Layout";
import photo from "../../images/photo.png";
import "./styles.scss";
import CardAdmin from "../../components/Card/CardAdmin";
import { Link } from "react-router-dom";

const StoreProfile = (props) => {
  return (
    <Layout>
      <section className="dash-section ">
        <div className="row">
          <div className="col-md-1">
            <h4>
              <Link to="/profile">
                {" "}
                <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>
              </Link>
            </h4>
          </div>
          <div className="row col-md-11">
            <div className="col-md-12 text-left pb-2">
              <CardAdmin>
                <div className="customer-info">
                  <p className="profile-title">Store information</p>
                  <div className="d-flex justify-content-between mb-3">
                    <span>
                      <span className="profile-small-title">Phone:</span>
                      <span className="profile-sub">07083454523</span>
                    </span>
                    <span>
                      <span className="profile-small-title">Birthday:</span>{" "}
                      <span className="profile-sub">August 02 2008</span>
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>
                      <span className="profile-small-title">Gender:</span>
                      <span className="profile-sub">female</span>
                    </span>
                    <span>
                      <span className="profile-small-title">Email:</span>
                      <span className="profile-sub">gloria@gmail.com</span>
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <span>
                      <span className="profile-small-title">Joined:</span>
                      <span className="profile-sub">August 2012</span>
                    </span>
                    <span className="">
                      <span className="profile-small-title">Location:</span>
                      <span className="profile-sub">Lagos</span>
                    </span>
                  </div>
                </div>
              </CardAdmin>
              <div className="float-right">
                <button className="btn btn-outline-danger btn-sm">
                  remove user
                </button>
              </div>
            </div>
            <div className="col-md-12">
              <CardAdmin>
                <div className="d-flex justify-content-between">
                  <p>Recent orders</p>
                  <p>view all orders</p>
                </div>

                <table class="table table-borderless table-hover table-striped profile-table table-responsive-sm">
                  <tbody>
                    <tr>
                      <td>Brazilian hair</td>
                      <td>#746815</td>
                      <td>24 Jun 2018, 09:30AM</td>
                      <td>Festival hair</td>
                      <td>N 100 000.00</td>
                      <td>
                        <i
                          class="fa fa-check"
                          style={{ color: "lightgreen" }}
                          aria-hidden="true"
                        ></i>
                      </td>
                    </tr>
                    <tr>
                      <td>Brazilian hair</td>
                      <td>#746815</td>
                      <td>24 Jun 2018, 09:30AM</td>
                      <td>Festival hair</td>
                      <td>N 100 000.00</td>
                      <td>
                        <i
                          class="fa fa-check"
                          style={{ color: "lightgreen" }}
                          aria-hidden="true"
                        ></i>
                      </td>
                    </tr>
                    <tr>
                      <td>Brazilian hair</td>
                      <td>#746815</td>
                      <td>24 Jun 2018, 09:30AM</td>
                      <td>Festival hair</td>
                      <td>N 100 000.00</td>
                      <td>
                        <i
                          class="fa fa-check"
                          style={{ color: "lightgreen" }}
                          aria-hidden="true"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardAdmin>
            </div>
            <div className="col-md-12">
              <CardAdmin>
                <p>Reviews</p>
                <table class="table table-borderless table-hover table-striped profile-table table-responsive-sm">
                  <tbody>
                    <tr>
                      <td>Brazilian hair</td>
                      <td>
                        A small river named Duden flows by their place and
                        supplies it with the necessary regelialia.
                      </td>
                      <td>
                        <i class="fa fa-star" aria-hidden="true"></i> 5.0
                      </td>
                    </tr>
                    <tr>
                      <td>Brazilian hair</td>
                      <td>
                        A small river named Duden flows by their place and
                        supplies it with the necessary regelialia.
                      </td>
                      <td>
                        <i class="fa fa-star" aria-hidden="true"></i> 5.0
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardAdmin>
            </div>
            <div className="col-md-12">
              <CardAdmin>
                <p>Delivery Address</p>
                <br />
                <p>ilupeju road</p>
                <p>Lagos</p>
                <p>Nigeria</p>
              </CardAdmin>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default StoreProfile;
