import {
  FETCH_COUPON,
  FETCH_COUPON_SUCCESS,
  FETCH_COUPON_FAILED
} from '../../types';

const initialState = {
  coupon: {},
  isLoading: false,
  errors: {},
  error: false
}

const fetchCouponReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_COUPON:
      return {
        ...state,
        isLoading: true,
          coupons: action.payload
      }
      case FETCH_COUPON_SUCCESS:
        return {
          ...state,
          isLoading: false,
            coupons: action.payload
        }
        case FETCH_COUPON_FAILED:
          return {
            ...state,
            isLoading: false,
              errors: action.payload,
              error: true
          }
          default:
            return {
              ...state
            };
  }
}

export default fetchCouponReducer;
