import React, { useState, useEffect, Fragment } from "react";
import Layout from "../../components/Layout/Layout";
import "./styles.scss";
import CardAdmin from "../../components/Card/CardAdmin";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import { connect } from "react-redux";
import PropTypes, { func } from "prop-types";
import {
  fetchUploadMedia,
  fetchAllMedia,
  fetchDeleteMedia,
} from "../../redux/actions/media";
import ViewMore from "../../components/ViewMore/ViewMore";

import Axios from "axios";
import { BASE_URL } from "../../redux/constant";
import Auth from "../../config/auth";
import swal from "sweetalert";

const Media = (props) => {
  const [file, setFile] = useState();
  const [images, setImages] = useState({});
  const [banner, setBanner] = useState([]);
  const [check, setCheck] = useState(false);
  const fileObj = [];
  const fileArray = [];
  const [pageCount, setPage] = useState(12);
  const [activeTab, setActiveTab] = useState(true);

  const toggle = () => setActiveTab(!activeTab);
  const btn_class = activeTab ? "active-btn" : "saved-btn";
  const btn_class2 = activeTab ? "saved-btn" : "active-btn";

  function viewMore() {
    props.fetchAllMedia(pageCount);
    setPage(pageCount + 12);
  }

  function uploadMultipleFiles(e) {
    //console.log(e.target.files);
    // fileObj.push(e.target.files);
    // for (let i = 0; i < fileObj[0].length; i++) {
    //   fileArray.push(URL.createObjectURL(fileObj[0][i]));
    // }
    setFile(e.target.files[0]);
  }

  function uploadFiles(e) {
    e.preventDefault();
    setCheck(true);
    const fd = new FormData();
    fd.append("images", file, "image1");
    props.fetchUploadMedia(fd);
    console.log(fd);
  }

  async function uploadBanner(e) {
    e.preventDefault();
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    setCheck(true);
    const fd = new FormData();
    fd.append("image", file, "image1");
    fd.append("store_id", "1585139305");
    try {
      const response = await Axios.post(`${BASE_URL}/merchants/banner`, fd, {
        headers,
      });
      if (response.data.hasError) {
        console.log(response.data);
        swal(response.data.errors.message, "", "error");
        setCheck(false);
      } else {
        swal(response.data.message, "", "success");
        setCheck(false);
        viewBanner();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteBanner(id) {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.delete(
        `${BASE_URL}/merchants/banner/1585139305/${id}`,
        {
          headers,
        }
      );
      if (response.data.hasError) {
        console.log(response.data);
        swal(response.data.errors.message, "", "error");
      } else {
        swal(response.data.message, "", "success");

        viewBanner();
      }
    } catch (error) {
      console.log(error);
    }
  }

  function deleteMedia(id) {
    props.fetchDeleteMedia(id);
  }

  async function viewBanner() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.get(
        `${BASE_URL}/merchants/store/view/1585139305`,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        setBanner(response.data.data.store.banners);
        console.log(response.data.data.store);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    props.fetchAllMedia();
    viewBanner();
  }, []);

  useEffect(() => {
    console.log(props.media.media.message);
    setFile("");
    if (props.media.media.data) {
      NotificationManager.success(props.media.media.message);
      setImages(props.media.media.data);
      setCheck(false);
    }
  }, [props.media.media.data]);

  useEffect(() => {
    setFile("");
    if (props.media.errors.errors) {
      NotificationManager.error(props.media.errors.errors.message);
      setCheck(false);
    }
  }, [props.media.errors.errors]);

  return (
    <Layout>
      <section className="dash-section ">
        <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <NotificationContainer />

        <div className="p-3 d-flex justify-content-center">
          <p className={btn_class} onClick={toggle}>
            Media
          </p>
          <p className={btn_class2} onClick={toggle}>
            Banner
          </p>
        </div>

        <div className="row dash-card-info-3">
          {activeTab && (
            <Fragment>
              <div className="col-md-12 ">
                <CardAdmin>
                  <h6>upload media</h6>
                  <br />
                  <form onSubmit={uploadFiles}>
                    <div className="form-group">
                      <input
                        type="file"
                        className="form-control"
                        onChange={uploadMultipleFiles}
                        multiple
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-danger btn-block"
                      disabled={check}
                    >
                      {check ? (
                        <div>
                          <span
                            className="spinner-border spinner-border-sm mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Uploading...
                        </div>
                      ) : (
                        "Upload"
                      )}
                    </button>
                  </form>
                </CardAdmin>
              </div>
              <div className="col-md-12 ">
                <CardAdmin>
                  <h6>Media</h6>
                  <div className="row">
                    {(images.media || []).map((url) => (
                      <div className="p-3">
                        <CardAdmin>
                          <div className="d-flex justtify-content-center">
                            <img
                              src={url.media_url}
                              width="150px"
                              height="150px"
                              alt="..."
                            />
                            <i
                              class="fa fa-window-close fa-lg pl-2"
                              aria-hidden="true"
                              onClick={() => deleteMedia(url.id)}
                            ></i>
                          </div>
                        </CardAdmin>
                      </div>
                    ))}
                  </div>
                </CardAdmin>
              </div>
            </Fragment>
          )}

          {!activeTab && (
            <Fragment>
              <div className="col-md-12 ">
                <CardAdmin>
                  <h6>upload banner</h6>
                  <br />
                  <form onSubmit={uploadBanner}>
                    <div className="form-group">
                      <input
                        type="file"
                        className="form-control"
                        onChange={uploadMultipleFiles}
                        multiple
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-danger btn-block"
                      disabled={check}
                    >
                      {check ? (
                        <div>
                          <span
                            className="spinner-border spinner-border-sm mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Uploading...
                        </div>
                      ) : (
                        "Upload banner"
                      )}
                    </button>
                  </form>
                </CardAdmin>
              </div>
              <div className="col-md-12 ">
                <CardAdmin>
                  <h6>Banner</h6>
                  <div className="row">
                    {(banner || []).map((url) => (
                      <div className="p-3">
                        <CardAdmin>
                          <div className="d-flex justtify-content-center">
                            <img
                              src={url.url}
                              width="150px"
                              height="150px"
                              alt="..."
                            />
                            <i
                              class="fa fa-window-close fa-lg pl-2"
                              aria-hidden="true"
                              onClick={() => deleteBanner(url.id)}
                            ></i>
                          </div>
                        </CardAdmin>
                      </div>
                    ))}
                  </div>
                </CardAdmin>
              </div>
            </Fragment>
          )}

          <div className="justify-content-center">
            <ViewMore viewHandler={viewMore} />
          </div>
        </div>
      </section>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  media: state.media,
});
const pageActions = { fetchAllMedia, fetchUploadMedia, fetchDeleteMedia };
export default connect(mapStateToProps, pageActions)(Media);
