import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import photo from "../../images/photo.png";
import "./styles.scss";
import CardAdmin from "../../components/Card/CardAdmin";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import numberToComma from "../../utils/numberToComma";

import Axios from "axios";
import { BASE_URL } from "../../redux/constant";
import Auth from "../../config/auth";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useEffect } from "react";
import swal from "sweetalert";
import dayjs from "dayjs";

const Notification = (props) => {
  const [notification, setNotification] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalPut, setModalPut] = useState(false);
  const [form, setForm] = useState({});
  const [check, setCheck] = useState(false);
  const [checkErr, setCheckErr] = useState(false);

  const toggle = () => setModal(!modal);
  const togglePut = () => setModalPut(!modalPut);

  function putTax(data) {
    setForm({
      name: data.taxes_name,
      amount: data.amount,
      id: data.id,
    });
    togglePut();
  }

  function addNotification() {
    setCheck(true);
    toggle();
  }

  function deleteNotification(id) {
    setCheck(true);
    swal({
      title: "Are you sure?",
      text: "Once removed, you will not be able to recover this User!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        remove(id);
        // swal("Poof! Your imaginary file has been deleted!", {
        //   icon: "success",
        // });
      } else {
       // swal("Your imaginary file is safe!");
      }
    });
  }

  async function fetch() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.get(`${BASE_URL}/notification/`, {
        headers,
      });
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        setNotification(response.data.data.notification);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function add() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = { title: form.title, body: form.message };
    try {
      const response = await Axios.post(`${BASE_URL}/notification/1585139305`, data, {
        headers,
      });
      if (response.data.hasError) {
        console.log(response.data);
        swal(response.data.errors.message, "", "error");
      } else {
        swal(response.data.message, "", "success");
        setCheck(false);
        setForm({title:"",message:""})
        toggle();
        fetch();
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function patch(id) {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = { ...form };
    try {
      const response = await Axios.put(
        `${BASE_URL}/staff/1585139305/${id}`,
        data,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        togglePut();
        swal(response.data.message, "", "success");
        fetch();
        setCheck(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function remove(id) {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.delete(`${BASE_URL}/staff/1585139305/${id}`, {
        headers,
      });
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        swal(response.data.message, "", "success");
        fetch();
        setCheck(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Layout>
      <section className="dash-section ">
        <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <div className="d-flex justify-content-between">
          <h3 className="pt-3 pb-3">Notification(s)</h3>
          <button
            onClick={addNotification}
            className="btn btn-primary"
          >
            Send Notification <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>

        <div className="table-responsive ">
          <table className="table table-borderless table-striped table-hover ">
            <thead>
              <tr>
                <th>Store ID</th>
                <th>Title</th>
                <th>Message</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {(notification || []).map((item, i) => {
                return (
                  <tr key={i}>
                <td>{item.store_id}</td>
                    <td>{item.title}</td>
                    <td>{item.message}</td>
                   
                    <td>{dayjs(item.createdAt).format("YYYY-MM-DD")}</td>

                    <td>
                      <button
                        onClick={() => deleteNotification(item.id)}
                        className="btn btn-sm btn-outline-danger"
                      >
                        remove
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Send Notification S</ModalHeader>
          <ModalBody>
          <div className="form-group mb-2">
              <label htmlFor="">Topic</label>
              <input
                type="text"
                name="item"
                className="form-control mr-3"
                placeholder="Topic"
                onChange={(e) =>
                  setForm({
                    ...form,
                    topic: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="">Title</label>
              <input
                type="email"
                name="item"
                className="form-control mr-3"
                placeholder="staff email"
                onChange={(e) =>
                  setForm({
                    ...form,
                    title: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="">Message</label>
              <input
                type="text"
                name="item"
                className="form-control mr-3"
                placeholder="staff role"
                onChange={(e) =>
                  setForm({
                    ...form,
                    message: e.target.value,
                  })
                }
                required
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button color="primary" onClick={add}>
              Send
            </Button>
          </ModalFooter>
        </Modal>
        {/* <Modal isOpen={modalPut} toggle={togglePut}>
          <ModalHeader toggle={toggle}>Edit Tax</ModalHeader>
          <ModalBody>
            <div className="form-group mb-2">
              <label htmlFor="">Tax</label>
              <input
                type="text"
                name="item"
                className="form-control mr-3"
                placeholder="tax name"
                value={form.name}
                onChange={(e) =>
                  setForm({
                    ...form,
                    name: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="">Amount</label>
              <input
                type="number"
                name="item"
                className="form-control mr-3"
                placeholder="amount"
                value={form.amount}
                onChange={(e) =>
                  setForm({
                    ...form,
                    amount: e.target.value,
                  })
                }
                required
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => patch(form?.id)}>
              Edit
            </Button>
          </ModalFooter>
        </Modal> */}
      </section>
    </Layout>
  );
};

Notification.propTypes = {};
const mapStateToProps = (state) => ({});

const pageActions = {};

export default connect(mapStateToProps, pageActions)(Notification);
