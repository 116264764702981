import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import photo from "../../images/photo.png";
import "./styles.scss";
import CardAdmin from "../../components/Card/CardAdmin";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import { Link, useHistory } from "react-router-dom";

import Axios from "axios";
import { BASE_URL } from "../../redux/constant";
import Auth from "../../config/auth";
import { useEffect } from "react";
import numberWithCommas from "../../utils/numberToComma";
import swal from "sweetalert";
import dayjs from "dayjs";

const Profile = (props) => {
  const history = useHistory();
  const [customers, setCustomer] = useState([]);
  const [stores, setStores] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const resCustomers = await Axios.get(
        `${BASE_URL}/merchants/store/view/users/1585139305`,
        { headers }
      );
      const resStores = await Axios.get(`${BASE_URL}/merchants/store/viewall`, {
        headers,
      });
      if (resCustomers.data.hasError) {
        swal(resCustomers.data.message, "", "error");
      } else {
        setCustomer(resCustomers.data.data.store.Users);
        console.log(resCustomers.data.data.store);
      }

      if (resStores.data.hasError) {
        swal(resCustomers.data.message, "", "error");
      } else {
        setStores(resStores.data.data.stores);
        console.log(resStores.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Layout>
      <section className="dash-section ">
        <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <div className="row dash-card-info-3">
          <div className="col-md-6 ">
            <CardAdmin>
              <table class="table table-borderless table-hover  table-responsive-sm">
                <thead>
                  <tr>
                    <th>Customers</th>
                    <th></th>
                    <th className="prod-admin-subtitle ">
                      sort by Ratings
                      <span>
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(customers || []).map((customer, i) => {
                    return (
                      <tr
                        onClick={() =>
                          history.push(`/customer-profile/${customer.id}`)
                        }
                      >
                        <td>
                          <img
                            className="admin-profile-container"
                            src={
                              customer.profile_pic_url === null
                                ? "https://aeealberta.org/wp-content/uploads/2018/10/profile.png"
                                : customer.profile_pic_url
                            }
                            alt="admin"
                            width="30%"
                          />
                        </td>

                        <td>
                          <div>
                            <p className="dash-info-title pb-3">
                              {customer.full_name}
                            </p>
                            <p className="dash-info-subtitle">
                              Last active on{" "}
                              {dayjs(customer.updatedAt).format("dddd MM YYYY")}
                            </p>
                            <p className="dash-info-subtitle">30 Orders</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="dash-info-subtitle pb-3">view</p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardAdmin>
          </div>
          <div className="col-md-6">
            <CardAdmin>
              <table class="table table-borderless table-hover  ">
                <thead>
                  <tr>
                    <th>Stores</th>
                    <th></th>

                    <th className="prod-admin-subtitle ">
                      sort by Ratings
                      <span>
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(stores || []).map((store, i) => {
                    return (
                      <tr
                        onClick={() =>
                          history.push(`/store-profile/${store.id}`)
                        }
                      >
                        <td>
                          <img
                            className="admin-profile-container"
                            src={
                              store.logo === null
                                ? "https://c8.alamy.com/comp/P2D424/store-vector-icon-isolated-on-transparent-background-store-logo-concept-P2D424.jpg"
                                : store.logo
                            }
                            alt="admin"
                            width="30%"
                            onError={`https://c8.alamy.com/comp/P2D424/store-vector-icon-isolated-on-transparent-background-store-logo-concept-P2D424.jpg`}
                          />
                        </td>

                        <td>
                          <div>
                            <p className="dash-info-title pb-3">
                              {store.store_name}
                            </p>
                            <p className="dash-info-subtitle">
                              Last active on{" "}
                              {dayjs(store.updatedAt).format("dddd MM YYYY")}
                            </p>
                            <p className="dash-info-subtitle">30 Orders</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="dash-info-subtitle pb-3">view</p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardAdmin>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Profile;
