export const FETCH_LOGIN = "FETCH_LOGIN";
export const FETCH_LOGIN_SUCCESS = "FETCH_LOGIN_SUCCESS";
export const FETCH_LOGIN_FAILED = "FETCH_LOGIN_FAILED";


export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";
export const FETCH_CATEGORY_FAILED = "FETCH_CATEGORY_FAILED";


export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAILED = "FETCH_PRODUCT_FAILED";

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILED = "FETCH_PRODUCTS_FAILED";

export const FETCH_MEDIA = "FETCH_MEDIA";
export const FETCH_MEDIA_SUCCESS = "FETCH_MEDIA_SUCCESS";
export const FETCH_MEDIA_FAILED = "FETCH_MEDIA_FAILED";

export const FETCH_COUPON = "FETCH_COUPON";
export const FETCH_COUPON_SUCCESS = "FETCH_COUPON_SUCCESS";
export const FETCH_COUPON_FAILED = "FETCH_COUPON_FAILED";

export const FETCH_COUPONS = "FETCH_COUPONS";
export const FETCH_COUPONS_SUCCESS = "FETCH_COUPONS_SUCCESS";
export const FETCH_COUPONS_FAILED = "FETCH_COUPONS_FAILED";

export const FETCH_ORDER = "FETCH_ORDER";
export const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS";
export const FETCH_ORDER_FAILED = "FETCH_ORDER_FAILED";

export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAILED = "FETCH_ORDERS_FAILED";

export const FETCH_AUCTIONS = "FETCH_AUCTIONS";
export const FETCH_AUCTIONS_SUCCESS = "FETCH_AUCTIONS_SUCCESS";
export const FETCH_AUCTIONS_FAILED = "FETCH_AUCTIONS_FAILED";

export const FETCH_AUCTION = "FETCH_AUCTION";
export const FETCH_AUCTION_SUCCESS = "FETCH_AUCTION_SUCCESS";
export const FETCH_AUCTION_FAILED = "FETCH_AUCTION_FAILED";

export const FETCH_AUCTION_BID = "FETCH_AUCTION_BID";
export const FETCH_AUCTION_BID_SUCCESS = "FETCH_AUCTION_BID_SUCCESS";
export const FETCH_AUCTION_BID_FAILED = "FETCH_AUCTION_BID_FAILED";

export const FETCH_BIDS = "FETCH_BIDS";
export const FETCH_BIDS_SUCCESS = "FETCH_BIDS_SUCCESS";
export const FETCH_BIDS_FAILED = "FETCH_BIDS_FAILED";

export const FETCH_INVENTORY = "FETCH_INVENTORY";
export const FETCH_INVENTORY_SUCCESS = "FETCH_INVENTORY_SUCCESS";
export const FETCH_INVENTORY_FAILED = "FETCH_INVENTORY_FAILED";

export const FETCH_INVENTORIES = "FETCH_INVENTORIES";
export const FETCH_INVENTORIES_SUCCESS = "FETCH_INVENTORIES_SUCCESS";
export const FETCH_INVENTORIES_FAILED = "FETCH_INVENTORIES_FAILED";

export const FETCH_SUBSCRIPTION = "FETCH_SUBSCRIPTION";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_SUBSCRIPTION_FAILED = "FETCH_SUBSCRIPTION_FAILED";

export const FETCH_SUBSCRIPTIONS = "FETCH_SUBSCRIPTIONS";
export const FETCH_SUBSCRIPTIONS_SUCCESS = "FETCH_SUBSCRIPTIONS_SUCCESS";
export const FETCH_SUBSCRIPTIONS_FAILED = "FETCH_SUBSCRIPTIONS_FAILED";

export const FETCH_DISPUTES = "FETCH_DISPUTES";
export const FETCH_DISPUTES_SUCCESS = "FETCH_DISPUTES_SUCCESS";
export const FETCH_DISPUTES_FAILED = "FETCH_DISPUTES_FAILED";

export const FETCH_DISPUTE = "FETCH_DISPUTE";
export const FETCH_DISPUTE_SUCCESS = "FETCH_DISPUTE_SUCCESS";
export const FETCH_DISPUTE_FAILED = "FETCH_DISPUTE_FAILED";

export const FETCH_ADDRESS = "FETCH_ADDRESS";
export const FETCH_ADDRESS_SUCCESS = "FETCH_ADDRESS_SUCCESS";
export const FETCH_ADDRESS_FAILED = "FETCH_ADDRESS_FAILED";

export const FETCH_ADDRESSES = "FETCH_ADDRESSES";
export const FETCH_ADDRESSES_SUCCESS = "FETCH_ADDRESSES_SUCCESS";
export const FETCH_ADDRESSES_FAILED = "FETCH_ADDRESSES_FAILED";

export const FETCH_IMPORT_PRODUCTS = "FETCH_IMPORT_PRODUCTS";
export const FETCH_IMPORT_PRODUCTS_SUCCESS = "FETCH_IMPORT_PRODUCTS_SUCCESS";
export const FETCH_IMPORT_PRODUCTS_FAILED = "FETCH_IMPORT_PRODUCTS_FAILED";

export const FETCH_IMPORT_PRODUCT = "FETCH_IMPORT_PRODUCT";
export const FETCH_IMPORT_PRODUCT_SUCCESS = "FETCH_IMPORT_PRODUCT_SUCCESS";
export const FETCH_IMPORT_PRODUCT_FAILED = "FETCH_IMPORT_PRODUCT_FAILED";

export const FETCH_WITHDRAWAL = "FETCH_WITHDRAWAL";
export const FETCH_WITHDRAWAL_SUCCESS = "FETCH_WITHDRAWAL_SUCCESS";
export const FETCH_WITHDRAWAL_FAILED = "FETCH_WITHDRAWAL_FAILED";