import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import "./styles.scss";
import swal from "sweetalert";
import CardAdmin from "../../components/Card/CardAdmin";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import { fetchCategory } from "../../redux/actions/category";
import { fetchAllMedia, fetchUploadMedia } from "../../redux/actions/media";
import {
  fetchAddProduct,
  fetchSingleProduct,
  fetchEditProduct,
} from "../../redux/actions/product";
import { fetchAddCategory } from "../../redux/actions/category";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "../../utils/isEmpty";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Axios from "axios";
import { BASE_URL } from "../../redux/constant";
import Auth from "../../config/auth";
import ModalCard from "../../components/ModalCard/ModalCard";
import truncate from "../../utils/truncate";

const EditProduct = (props) => {
  const {
    match: { params },
  } = props;
  const [category, setCategory] = useState([]);
  const [form, setForm] = useState({});
  const [images, setImages] = useState({});
  const [imagesUrl, setImagesUrl] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalVariation, setModalVariation] = useState(false);
  const [modalEditVariation, setModalEditVariation] = useState(false);
  const [modalCategory, setModalCategory] = useState(false);
  const [modalSubCat, setModalSubCat] = useState(false);
  const [variation, setVariation] = useState(false);
  const [variationItem, setVariationItem] = useState([]);
  const [variationObject, setvariationObject] = useState({});
  const [file, setFile] = useState();
  const [modalPreview, setModalPreview] = useState(false);
  const [cat, setCat] = useState({});
  const [check, setCheck] = useState(false);
  const [catKey, setCatKey] = useState(0);
  const [subKey, setSubKey] = useState("")
  const [editImage, setEditImage] = useState([])

  const togglePreview = () => setModalPreview(!modalPreview);
  const toggle = () => setModal(!modal);
  const toggleVariation = () => setModalVariation(!modalVariation);
  const toggleEditVariation = () => setModalEditVariation(!modalEditVariation);
  const toggleCategory = () => setModalCategory(!modalCategory);
  const toggleSubCat = () => setModalSubCat(!modalSubCat);

  useEffect(() => {
    props.fetchSingleProduct(params.product);
    props.fetchCategory();
    props.fetchAllMedia();
  }, []);

  useEffect(() => {
    if (props.category.category.data) {
      setCategory(props.category.category.data.store.categories);
      if (check) {
        swal("category added");
      }
    }
  }, [props.category.category.data]);

  useEffect(() => {
    console.log(props.media.media.message);
    if (props.media.media.data) {
      setFile("");
      setImages(props.media.media.data);
    }
  }, [props.media.media]);

  useEffect(() => {
    console.log(props.product);
    if (!isEmpty(props.product.product.data)) {
      setForm(props.product.product.data.product);
      setVariationItem(props.product.product.data.product?.product_variations);
      setVariation(props.product.product.data.product.hasVariation);
      setImagesUrl(props.product.product.data.product.images);
      console.log(category);
      category.map((cat,i)=>{
        //console.log(i, cat.id, props.product.product.data.product.categoty_id, cat.id === props.product.product.data.product.categoty_id)
        if (cat.id === props.product.product.data.product.categoty_id ) {
          setCatKey(i);
          category[i].subCategories.map((name,i)=>{
            if(name === props.product.product.data.product.sub_category){
              setSubKey(name)
            }
          })
          console.log("Condition met", catKey, i);
        }

      })
      console.log(catKey)
    }
    if (check) {
      swal("edit successfull", "", "success");
      setCheck(false);
    }
  }, [props.product.product]);

  useEffect(() => {
    console.log(props.product);
    if (!isEmpty(props.product.errors.errors)) {
    
      if(check){
        NotificationManager.error(props.product.errors.errors.message);
      props.product.errors.errors = {};
      setCheck(false)
      } else {
        NotificationManager.error(props.product.errors.errors.message);
        props.product.errors.errors = {};
        setForm({
          product_name: "",
          description: "",
          specification: "",
          stock_limit: "",
          price: "",
          categoty_id: "",
          phone: "",
          discount:""
        });
        setImagesUrl([]);
        setVariation();
        setVariationItem([]);
      }
    }
  }, [props.product.errors.errors]);

  function editProduct(e) {
    e.preventDefault();
    setCheck(true);
    let data = {
      ...form,
      images: editImage,
      categoty_id: category[catKey].id
    };
    props.fetchEditProduct(params.product, data);
  }

  function handleSelect(e) {
    if (e.target.type === "checkbox" && !e.target.checked) {
      setImagesUrl(imagesUrl.filter((item) => item !== e.target.value));
      setEditImage(editImage.filter((item) => item !== e.target.value));
    } else {
      setImagesUrl([...imagesUrl, e.target.value]);
      setEditImage([...editImage, e.target.value]);
    }
  }

  function removeImage(url) {
    let n = imagesUrl.includes(url);
    if (n) {
      setImagesUrl(imagesUrl.filter((item) => item !== url));
    }
  }

  async function postVariation() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    console.log(headers);
    if (variation) {
      try {
        let data = {
          product_id: params.product,
          variation: [variationObject],
        };
        console.log(data);
        const response = await Axios.post(
          `${BASE_URL}/merchants/products/variation/add`,
          data,
          { headers }
        );
        console.log(response.data);
        if (response.data.hasError) {
          swal(response.data.message, "", "error");
        } else {
          swal(response.data.message, "", "success");
          props.fetchSingleProduct(params.product);
          setvariationObject({});
          toggleVariation();
        }
      } catch (error) {
        console.log(error);
        swal("variation failed", "", "error");
      }
    }
  }

  async function deleteVariation(id) {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    console.log(headers);
    if (variation) {
      try {
        const response = await Axios.delete(
          `${BASE_URL}/merchants/delete-variation/${id}`,
          { headers }
        );
        console.log(response.data);
        if (response.data.hasError) {
          swal(response.data.message, "", "error");
        } else {
          swal(response.data.message, "", "success");
          props.fetchSingleProduct(params.product);
        }
      } catch (error) {
        console.log(error);
        swal("delete variation failed", "", "error");
      }
    }
  }

  function deleteVariationView(id){
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this variation file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
          deleteVariation(id)
        // swal("Poof! Your imaginary file has been deleted!", {
        //   icon: "success",
        // });
      } else {
       // swal("Your imaginary file is safe!");
      }
    });
  }

  async function editVariation() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    //console.log(headers);
    if (variation) {
      try {
        let data = {
          variation_id: variationObject.id,
          variation: variationObject.variation,
          price: variationObject.price,
          discount: variationObject.discount,
          discount_type: variationObject.discount_type,
        };
        const response = await Axios.put(
          `${BASE_URL}/merchants/update-variation`,
          data,
          { headers }
        );
        console.log(response.data);
        if (response.data.hasError) {
          swal(response.data.message, "", "error");
        } else {
          swal(response.data.message, "", "success");
          props.fetchSingleProduct(params.product);
          setvariationObject({});
          toggleEditVariation();
        }
      } catch (error) {
        console.log(error);
        swal("edit variation failed", "", "error");
      }
    }
  }

  async function deleteImage(url) {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`
    };
    console.log(headers);
    if (variation) {
      try {
        const data= { image_url : url}
        const response = await Axios.delete(
          `${BASE_URL}/merchants/products/image/${params.product}`,
          { headers , data}
        );
        console.log(response.data);
        if (response.data.hasError) {
          swal(response.data.message, "", "error");
        } else {
          swal(response.data.message, "", "success");
          props.fetchSingleProduct(params.product);
        }
      } catch (error) {
        console.log(error);
        swal("delete image failed", "", "error");
      }
    }
  }

  function editVar(item) {
    setvariationObject(item);
    toggleEditVariation();
  }

  // function removeVariation(key) {
  //   setVariationItem(variationItem.filter((item) => item !== key));
  // }

  function uploadMultipleFiles(e) {
    setFile(e.target.files[0]);
  }

  function uploadFiles(e) {
    e.preventDefault();
    const fd = new FormData();
    fd.append("images", file, "image1");
    props.fetchUploadMedia(fd);
    console.log(fd);
  }

  function addCat(e) {
    e.preventDefault();
    setCheck(true);
    props.fetchAddCategory({ name: cat.name, store_id: "1585139305" });
    setModalCategory(!modalCategory);
    console.log(cat);
  }

  function addSubCat(e) {
    e.preventDefault();
    console.log(cat);
  }

  return (
    <Layout>
      { console.log(catKey)}
      <section className="dash-section ">
        <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <NotificationContainer />
        <div className="row dash-card-info-3">
          <div className="col-md-12 ">
            <CardAdmin>
              <h4>Edit product</h4>
              <form className="p-3 form" onSubmit={editProduct}>
                <div className="form-group mb-2">
                  <label htmlFor="">Product name</label>
                  <input
                    type="text"
                    name="item"
                    className="form-control product-input"
                    required
                    value={form.product_name}
                    onChange={(e) =>
                      setForm({ ...form, product_name: e.target.value })
                    }
                  />
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="">Description</label>

                  <textarea
                    type="text"
                    name="description"
                    className="form-control product-input"
                    required
                    value={form.description}
                    onChange={(e) =>
                      setForm({ ...form, description: e.target.value })
                    }
                    rows="3"
                  ></textarea>
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="">Specification</label>
                  <textarea
                    type="text"
                    name="description"
                    className="form-control product-input"
                    value={form.specification}
                    onChange={(e) =>
                      setForm({ ...form, specification: e.target.value })
                    }
                    rows="3"
                  ></textarea>
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="">Add tag</label>
                  <input
                    type="text"
                    name="tags"
                    className="form-control product-input"
                    value={form.tags}
                    onChange={(e) => setForm({ ...form, tags: e.target.value })}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="">Phone number</label>
                  <input
                    type="tel"
                    name="phone"
                    className="form-control product-input"
                    required
                    value={form.phone}
                    onChange={(e) =>
                      setForm({ ...form, phone: e.target.value })
                    }
                  />
                </div>
                {!variation && (
                  <div>
                    <div className="form-group mb-2">
                      <label htmlFor="">Stock Limit</label>
                      <input
                        type="number"
                        name="stock"
                        className="form-control product-input"
                        required
                        value={form.stock_limit}
                        onChange={(e) =>
                          setForm({ ...form, stock_limit: e.target.value })
                        }
                      />
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="">Price</label>
                      <input
                        type="number"
                        name="price"
                        className="form-control product-input"
                        required
                        value={form.price}
                        onChange={(e) =>
                          setForm({ ...form, price: e.target.value })
                        }
                      />
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6  mb-3">
                        <input
                          type="number"
                          name="discount"
                          placeholder="discount %"
                          className="form-control product-input"
                          required
                          value={form.discount}
                          onChange={(e) =>
                            setForm({ ...form, discount: e.target.value })
                          }
                        />
                      </div>

                      <div className="col-md-6 row">
                        <label htmlFor="tax">Charge taxes</label>
                        <label class="switch ml-2">
                          <input type="checkbox" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>

                    <label htmlFor="">Discount type</label>
                    <div className="input-group mb-3">
                      <select
                        className="custom-select product-select"
                        id="inputGroupSelect01"
                        value={form.discount_type}
                        onChange={(e) =>
                          setForm({ ...form, discount_type: e.target.value })
                        }
                      >
                        <option selected>Select discount type</option>
                        <option>value</option>
                        <option>percent</option>
                      </select>
                    </div>
                  </div>
                )}
                <label htmlFor="">Category</label>
                <div className="row">
                  <div className="input-group mb-3 col-md-10">
                    <select
                      className="custom-select product-select"
                      id="inputGroupSelect01"
                      value={catKey}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          categoty_id: e.target.value,
                        });
                        setCatKey(e.target.value);
                      }}
                    >
                      <option selected>Select Categories...</option>
                      {category.map((cat, i) => {
                        return (
                          <option key={i} value={i}>
                            {cat.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-2">
                    <p
                      onClick={toggleCategory}
                      className="btn btn-outline-secondary  text-center pt-2"
                    >
                      add new
                    </p>
                  </div>
                </div>

                <label htmlFor="">Sub Category</label>
                <div className="row">
                  <div className="input-group mb-3 col-md-10">
                    <select
                      className="custom-select product-select"
                      id="inputGroupSelect01"
                      value={subKey}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          sub_category: e.target.value,
                        });
                        setSubKey(e.target.value);
                      }}
                    >
                      <option selected>Select Sub Categories...</option>
                      {(
                        (category.length > 1 &&
                          category[catKey].subCategories) ||
                        []
                      ).map((name, i) => {
                        return (
                          <option key={i} value={name}>
                            {name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-2">
                    <p
                      onClick={toggleSubCat}
                      className="btn btn-outline-secondary  text-center pt-2"
                    >
                      add new
                    </p>
                  </div>
                </div>

                <div className="variation-container">
                  <div className="p-2">
                    <i
                      onClick={toggle}
                      className="fa fa-camera fa-4x variation-images"
                      aria-hidden="true"
                    ></i>
                  </div>
                  {(imagesUrl || []).map((url, i) => (
                    <div className="p-2" key={i}>
                      <img
                        className="variation-images"
                        src={url}
                        width="110px"
                        height="80px"
                        alt="variation"
                      />
                      <i
                        onClick={() => deleteImage(url)}
                        className="fa fa-trash"
                        aria-hidden="true"
                      ></i>
                    </div>
                  ))}
                </div>

                <div className="mb-3">
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Select image</ModalHeader>
                    <ModalBody>
                      <div>
                        <div>
                          <form>
                            <div className="form-group">
                              <input
                                type="file"
                                className="form-control"
                                onChange={uploadMultipleFiles}
                                multiple
                              />
                            </div>
                            <button
                              type="button"
                              className="btn btn-danger btn-block"
                              onClick={uploadFiles}
                            >
                              Upload
                            </button>
                          </form>
                        </div>

                        <div className="row">
                          {(images.media || []).map((url, i) => (
                            <div className="p-2" key={i}>
                              <input
                                name="images"
                                type="checkbox"
                                value={url.media_url}
                                checked={imagesUrl.includes(url.media_url)}
                                onChange={handleSelect}
                              />
                              <img
                                src={url.media_url}
                                width="100px"
                                height="100px"
                                alt="..."
                                className="variation-images"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={toggle}>
                        ok
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>

                <div className="pt-2 d-flex justify-content-between">
                  <label htmlFor="tax">Publish product</label>
                  <label class="switch ml-2">
                    <input
                      type="checkbox"
                      checked={form.published}
                      value={form.published}
                      onChange={() =>
                        setForm({ ...form, published: !form.published })
                      }
                    />
                    <span class="slider round"></span>
                  </label>
                </div>

                <div className=" pt-2 d-flex justify-content-between">
                  <label htmlFor="variation">Product variation</label>
                  <label class="switch ml-2">
                    <input
                      type="checkbox"
                      checked={variation}
                      value={variation}
                      onChange={() => {
                        setVariation(!variation);
                        if (!variation) {
                          setModalVariation(!modalVariation);
                        }
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>

                {variation && (
                  <div className="variation-container">
                    <div>
                      <p
                        onClick={toggleVariation}
                        className="btn btn-outline-secondary  text-center pt-2"
                      >
                        add new
                      </p>
                    </div>
                    {(variationItem || []).map((item, i) => (
                      <div
                        className="p-2 m-2 variation-items d-flex justify-content-between"
                        key={i}
                      >
                        <div>
                          <p>
                            Variation : <strong> {item.variation}</strong>
                          </p>
                          <p>
                            Quantity : <strong>{item.quantity}</strong>
                          </p>
                          <p>
                            Price : <strong>{item.price}</strong>
                          </p>
                          <p>
                            Discount :{" "}
                            <strong>
                              {item.discount_type === "value" && "₦"}{" "}
                              {item.discount}{" "}
                              {item.discount_type === "percent" && "%"}{" "}
                            </strong>
                          </p>
                        </div>
                        <div className="pl-2">
                          <i
                            className="fa fa-pencil text-secondary font-weight-bold"
                            aria-hidden="true"
                            title="Edit"
                            onClick={() => editVar(item)}
                          ></i>
                        </div>
                        <div className="pl-2">
                          <i
                            className="fa fa-trash text-danger font-weight-bold"
                            aria-hidden="true"
                            title="Delete"
                            onClick={() => deleteVariationView(item.id)}
                          ></i>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <p
                  className="btn btn-block btn-outline-secondary  text-center pt-2"
                  onClick={togglePreview}
                >
                  preview
                </p>
                <button
                  type="submit"
                  className="btn btn-block publish-btn  text-center pt-2"
                >
                  Update product
                </button>
              </form>
            </CardAdmin>
          </div>
          <Modal isOpen={modalVariation} toggle={toggleVariation}>
            <ModalHeader toggle={toggleVariation}>Add variation</ModalHeader>
            <ModalBody>
              <form className="form">
                <div className="p-3">
                  <div className="">
                    <div className="form-group mb-2">
                      <label htmlFor="">Variation</label>
                      <input
                        type="text"
                        name="item"
                        className="form-control mr-3"
                        placeholder="enter variation"
                        value={variationObject.variation}
                        onChange={(e) =>
                          setvariationObject({
                            ...variationObject,
                            variation: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="">
                    <div className="form-group mb-2">
                      <label htmlFor="">Quantity</label>
                      <input
                        type="number"
                        name="item"
                        className="form-control mr-3"
                        value={variationObject.quantity}
                        onChange={(e) =>
                          setvariationObject({
                            ...variationObject,
                            quantity: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-2">
                      <label htmlFor="">Price</label>
                      <input
                        type="number"
                        name="item"
                        className="form-control "
                        value={variationObject.price}
                        onChange={(e) =>
                          setvariationObject({
                            ...variationObject,
                            price: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="">Discount</label>
                      <input
                        type="number"
                        name="item"
                        className="form-control mr-3"
                        value={variationObject.discount}
                        onChange={(e) =>
                          setvariationObject({
                            ...variationObject,
                            discount: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <label htmlFor="">Discount type</label>
                  <div className="input-group mb-3">
                    <select
                      className="custom-select"
                      id="inputGroupSelect01"
                      value={variationObject.discount_type}
                      onChange={(e) =>
                        setvariationObject({
                          ...variationObject,
                          discount_type: e.target.value,
                        })
                      }
                    >
                      <option selected>Select discount type</option>
                      <option>value</option>
                      <option>percent</option>
                    </select>
                  </div>
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={postVariation}>
                add variation
              </Button>
            </ModalFooter>
          </Modal>
          <div className="mb-3">
            <Modal isOpen={modalCategory} toggle={toggleCategory}>
              <ModalHeader toggle={toggleCategory}>Add category</ModalHeader>
              <ModalBody>
                <form className="form">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="enter category"
                      onChange={(e) => setCat({ ...cat, name: e.target.value })}
                    />
                  </div>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={addCat}>
                  add
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div className="mb-3">
            <Modal isOpen={modalSubCat} toggle={toggleSubCat}>
              <ModalHeader toggle={toggleSubCat}>Add category</ModalHeader>
              <ModalBody>
                <form className="form">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="enter sub category"
                      onChange={(e) => setCat({ ...cat, name: e.target.value })}
                    />
                  </div>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={addSubCat}>
                  add
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <ModalCard
            title={form?.product_name}
            toggle={togglePreview}
            modal={modalPreview}
          >
            {/* <img src={form.images === null ?'': form?.images} width="50%" alt="product-image"/> */}
            <p>
              <strong>Name:</strong> {form?.product_name}
            </p>
            <p>
              <strong>Description:</strong>{" "}
              {truncate(form?.description ? form?.description : "", 200)}
            </p>
            <p>
              <strong>Specification:</strong> {form?.specification}
            </p>
            <p>
              <strong>Tags:</strong> {form?.tags}
            </p>
            <p>
              <strong>Phone:</strong> {form?.phone}
            </p>
            <p>
              <strong>Stock limit:</strong> {form?.stock_limit}
            </p>
            <p>
              <strong>Price:</strong> {form?.price}
            </p>
            <p>
              <strong>Discount:</strong>{" "}
              {form?.discount_type === "value" && "₦"} {form?.discount}{" "}
              {form?.discount_type === "percent" && "%"}
            </p>
            <strong>Variations</strong>
            <div className="variation-container bg-gray">
              {(variationItem || []).map((item, i) => (
                <div key={i}>
                  <div>
                    <p>
                      Variation : <strong> {item.variation}</strong>
                    </p>
                    <p>
                      Quantity : <strong>{item.quantity}</strong>
                    </p>
                    <p>
                      Price : <strong>{item.price}</strong>
                    </p>
                    <p>
                      Discount :{" "}
                      <strong>
                        {item.discount_type === "value" && "₦"} {item.discount}{" "}
                        {item.discount_type === "percent" && "%"}{" "}
                      </strong>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </ModalCard>

          <Modal isOpen={modalEditVariation} toggle={toggleEditVariation}>
            <ModalHeader toggle={toggleEditVariation}>Edit variation</ModalHeader>
            <ModalBody>
              <form className="form">
                <div className="p-3">
                  <div className="">
                    <div className="form-group mb-2">
                      <label htmlFor="">Variation</label>
                      <input
                        type="text"
                        name="item"
                        className="form-control mr-3"
                        placeholder="enter variation"
                        value={variationObject.variation}
                        onChange={(e) =>
                          setvariationObject({
                            ...variationObject,
                            variation: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  {/* <div className="">
                    <div className="form-group mb-2">
                      <label htmlFor="">Quantity</label>
                      <input
                        type="number"
                        name="item"
                        className="form-control mr-3"
                        value={variationObject.quantity}
                        onChange={(e) =>
                          setvariationObject({
                            ...variationObject,
                            quantity: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div> */}
                  <div className="">
                    <div className="form-group mb-2">
                      <label htmlFor="">Price</label>
                      <input
                        type="number"
                        name="item"
                        className="form-control "
                        value={variationObject.price}
                        onChange={(e) =>
                          setvariationObject({
                            ...variationObject,
                            price: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="">Discount</label>
                      <input
                        type="number"
                        name="item"
                        className="form-control mr-3"
                        value={variationObject.discount}
                        onChange={(e) =>
                          setvariationObject({
                            ...variationObject,
                            discount: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <label htmlFor="">Discount type</label>
                  <div className="input-group mb-3">
                    <select
                      className="custom-select"
                      id="inputGroupSelect01"
                      value={variationObject.discount_type}
                      onChange={(e) =>
                        setvariationObject({
                          ...variationObject,
                          discount_type: e.target.value,
                        })
                      }
                    >
                      <option selected>Select discount type</option>
                      <option>value</option>
                      <option>percent</option>
                    </select>
                  </div>
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={editVariation}>
                edit variation
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </section>
    </Layout>
  );
};

EditProduct.propTypes = {
  category: PropTypes.object.isRequired,
  fetchCategory: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  category: state.category,
  media: state.media,
  product: state.product,
});

const pageActions = {
  fetchCategory,
  fetchAllMedia,
  fetchAddProduct,
  fetchUploadMedia,
  fetchAddCategory,
  fetchSingleProduct,
  fetchEditProduct,
};

export default connect(mapStateToProps, pageActions)(EditProduct);
