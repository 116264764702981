import axios from 'axios';
import {
  FETCH_CATEGORY,
  FETCH_CATEGORY_FAILED,
  FETCH_CATEGORY_SUCCESS
} from '../types';
import {
  BASE_URL
} from '../constant'
import Auth from '../../config/auth'


export const fetchCategory = () => dispatch => {

  axios.get(`${BASE_URL}/products/category/list/1585139305`)
    .then(response => {
      console.log(response)
      if (!response.hasError) {
        dispatch({
          type: FETCH_CATEGORY_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_CATEGORY_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_CATEGORY_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchAddCategory = (data) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.post(`${BASE_URL}/merchants/stores/category`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.hasError) {
        // dispatch({
        //   type: FETCH_CATEGORY_SUCCESS,
        //   payload: response.data
        // })
        dispatch(fetchCategory())
      } else {
        dispatch({
          type: FETCH_CATEGORY_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_CATEGORY_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


// export const fetchCategoryProduct = (id) => dispatch => {

//   axios.get(`${BASE_URL}/products/view/shobbu/${id}`)
//     .then(response => {
//       console.log(response)
//       if (!response.hasError) {
//         dispatch({
//           type: FETCH_CATEGORY_PRODUCT_SUCCESS,
//           payload: response.data
//         })
//       } else {
//         dispatch({
//           type: FETCH_CATEGORY_PRODUCT_FAILED,
//           payload: response.data
//         })
//       }
//     })
//     .catch(err => {
//       dispatch({
//         type: FETCH_CATEGORY_FAILED,
//         payload: {
//           hasError: true,
//           errors: {
//             message: "network error",
//             error: err.data
//           }
//         }
//       })
//     })
// }
