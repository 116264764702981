import {
  FETCH_MEDIA,
  FETCH_MEDIA_SUCCESS,
  FETCH_MEDIA_FAILED
} from '../types';
import axios from 'axios'

import {
  BASE_URL
} from '../constant';

import Auth from '../../config/auth'


export const fetchUploadMedia = (data) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.post(`${BASE_URL}/merchants/media/upload/1585139305`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch(fetchAllMedia())
        // dispatch({
        //   type: FETCH_MEDIA_SUCCESS,
        //   payload: response.data
        // })
      } else {
        dispatch({
          type: FETCH_MEDIA_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_MEDIA_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}



export const fetchAllMedia = (count) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  var pageCount = 12 + (count ? count : 0);
  axios.get(`${BASE_URL}/merchants/media/1585139305?pages=1&paginate=${pageCount}`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_MEDIA_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_MEDIA_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_MEDIA_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchDeleteMedia = (id) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.delete(`${BASE_URL}/merchants/media/${id}`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch(fetchAllMedia())
        // dispatch({
        //   type: FETCH_MEDIA_SUCCESS,
        //   payload: response.data
        // })
      } else {
        dispatch({
          type: FETCH_MEDIA_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_MEDIA_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}
