import React from "react";
import "./styles.scss";

const CardAdmin = (props) => {
  return (
    <div className="admin-card">
      <div className="admin-card-body">{props.children}</div>
    </div>
  );
};

export default CardAdmin;
