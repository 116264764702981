import React, { useState } from "react";
import { Chart } from "react-charts";
import Axios from "axios";
import { BASE_URL } from "../../redux/constant";
import Auth from "../../config/auth";
import { useEffect } from "react";

function MyChart() {
  const [chartsdata, setChartsdata] = useState([]);
  async function fetch() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.get(
        `${BASE_URL}/merchants/graphs/weekly/1585139305`,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        setChartsdata(response.data.data.weekly_revenue);
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const series = React.useMemo(
    () => ({
      type: "bar",
    }),
    []
  );
  const data = React.useMemo(
    () => [
      {
        label: "Series 1",
        data: [
          [0, 1,],
          [1, 2],
          [2, 3],
          [3, 4],
          [5, 5],
        ],
      },
      // {
      //   label: "Series 2",
      //   data: [
      //     [0, 3],
      //     [1, 1],
      //     [2, 5],
      //     [3, 6],
      //     [4, 4],
      //   ],
      // },
    ],
    []
  );
  
  const getSeriesStyle = React.useCallback(
    (series) => ({
      color: "#0279B8",
    }),
    []
  );

  const axes = React.useMemo(
    () => [
      { primary: true, type: "ordinal", position: "bottom" },
      { type: "linear", position: "left" },
    ],
    []
  );

  useEffect(()=>{
    fetch();
  },[])

  return (
    // A react-chart hyper-responsively and continuously fills the available
    // space of its parent element automatically
    <div
      style={{
        width: "100%",
        height: "350px",
      }}
    >
      <Chart data={data} series={series} axes={axes} />
    </div>
  );
}
//getSeriesStyle={getSeriesStyle}

export default MyChart;
