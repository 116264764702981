import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import photo from "../../images/photo.png";
import "./styles.scss";
import CardAdmin from "../../components/Card/CardAdmin";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import numberToComma from "../../utils/numberToComma";

import Axios from "axios";
import { BASE_URL } from "../../redux/constant";
import Auth from "../../config/auth";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useEffect } from "react";
import swal from "sweetalert";
import dayjs from "dayjs";

const Tax = (props) => {
  const [taxes, setTaxes] = useState([]);
  const [tax, setTax] = useState({});
  const [modal, setModal] = useState(false);

  const [request, setRequest] = useState({});
  const [form, setForm] = useState({});
  const [check, setCheck] = useState(false);
  const [checkErr, setCheckErr] = useState(false);

  const toggle = () => setModal(!modal);

  function addTax() {
    setCheck(true);
    toggle();
  }

  function deleteTax(id) {
    setCheck(true);
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Tax!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        remove(id);
        // swal("Poof! Your imaginary file has been deleted!", {
        //   icon: "success",
        // });
      } else {
        swal("Your imaginary file is safe!");
      }
    });
  }

  async function fetch() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.get(`${BASE_URL}/tax/1585139305`, {
        headers,
      });
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        setTaxes(response.data.data.tax);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function add() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = { name: form.name, amount: form.amount };
    try {
      const response = await Axios.post(`${BASE_URL}/tax/1585139305`, data, {
        headers,
      });
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        swal(response.data.message, "", "success");
        setCheck(false);
        toggle();
        fetch();
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function patch(id) {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = { ...form };
    try {
      const response = await Axios.put(
        `${BASE_URL}/tax/1585139305/${id}`,
        data,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        swal(response.data.message, "", "success");
        fetch();
        setCheck(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function remove(id) {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.delete(`${BASE_URL}/tax/1585139305/${id}`, {
        headers,
      });
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        swal(response.data.message, "", "success");
        fetch();
        setCheck(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Layout>
      <section className="dash-section ">
        <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <div className="d-flex justify-content-between">
          <h3 className="pt-3 pb-3">Tax(es)</h3>
          <button
            onClick={addTax}
            className="btn btn-primary"
          >
            add tax <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>

        <div className="table-responsive ">
          <table className="table table-borderless table-striped table-hover ">
            <thead>
              <tr>
                <th>Store id</th>
                <th>Tax</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {(taxes || []).map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.store_id}</td>
                    <td>{item.taxes_name}</td>
                    <td>{numberToComma(item.amount)}</td>
                    <td>
                      <span
                        className={
                          item.selected
                            ? `badge badge-success`
                            : `badge badge-danger`
                        }
                      >
                        {item.selected ? "selected" : "not selected"}
                      </span>
                    </td>
                    <td>{dayjs(item.createdAt).format("YYYY MM DD")}</td>

                    <td>
                    <label class="switch mr-2">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      value={item.selected}
                      onChange={() => patch(item.id)}
                    />
                    <span class="slider round"></span>
                  </label>
                     
                      <button
                        onClick={() => deleteTax(item.id)}
                        className="btn btn-sm btn-outline-danger"
                      >
                        delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Add Tax</ModalHeader>
          <ModalBody>
            <div className="form-group mb-2">
              <label htmlFor="">Tax</label>
              <input
                type="text"
                name="item"
                className="form-control mr-3"
                placeholder="tax name"
                onChange={(e) =>
                  setForm({
                    ...form,
                    name: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="">Amount</label>
              <input
                type="number"
                name="item"
                className="form-control mr-3"
                placeholder="coupon name"
                onChange={(e) =>
                  setForm({
                    ...form,
                    amount: e.target.value,
                  })
                }
                required
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button color="primary" onClick={add}>
              Add
            </Button>
          </ModalFooter>
        </Modal>
      
      </section>
    </Layout>
  );
};

Tax.propTypes = {};
const mapStateToProps = (state) => ({});

const pageActions = {};

export default connect(mapStateToProps, pageActions)(Tax);
