import {
  combineReducers
} from 'redux';

import fetchLoginEmailReducer from './authReducers/fetchLoginEmailReducer';
import fetchCategoryReducer from './productReducers/fetchCategoryReducer';
import fetchProductReducer from './productReducers/fetchProductReducer';
import fetchProductsReducer from './productReducers/fetchProductsReducer';
import fetchMediaReducer from './mediaReducer/mediaReducer';
import fetchCouponReducer from './productReducers/fetchCouponReducer';
import fetchCouponsReducer from './productReducers/fetchCouponsReducer';
import fetchOrdersReducer from './productReducers/fetchOrdersReducer';
import fetchAuctionsReducer from './auctionReducer/fetchAuctionsReducer';
import fetchAuctionReducer from './auctionReducer/fetchAuctionReducer';
import fetchAuctionBidReducer from './auctionReducer/fetchAuctionBidReducer';
import fetchBidsReducer from './auctionReducer/fetchBidsReducer';
import fetchSubscriptionReducer from './productReducers/fetchSubscriptionReducer';
import fetchInventoryReducer from './productReducers/fetchInventoryReducer';
import fetchOrderReducer from './productReducers/fetchOrderReducer';
import fetchDisputeReducer from './productReducers/fetchDisputeReducer';
import fetchDisputesReducer from './productReducers/fetchDisputesReducer';
import fetchInventoriesReducer from './productReducers/fetchInventoriesReducer';
import fetchAddressReducer from './productReducers/fetchAddressReducer';
import fetchAddressesReducer from './productReducers/fetchAddressesReducer';
import fetchImportProductsReducer from './productReducers/fetchImportProductsReducer';
import fetchWithdrawalReducer from './productReducers/fetchWithdrawalReducer';
import fetchSubscriptionsReducer from './productReducers/fetchSubscriptionsReducer';

export default combineReducers({
  login: fetchLoginEmailReducer,
  category: fetchCategoryReducer,
  product: fetchProductReducer,
  products: fetchProductsReducer,
  media: fetchMediaReducer,
  coupon: fetchCouponReducer,
  coupons: fetchCouponsReducer,
  orders: fetchOrdersReducer,
  order: fetchOrderReducer,
  auctions: fetchAuctionsReducer,
  auction: fetchAuctionReducer,
  auctionBid: fetchAuctionBidReducer,
  bids: fetchBidsReducer,
  subscription: fetchSubscriptionReducer,
  subscriptions: fetchSubscriptionsReducer,
  inventory: fetchInventoryReducer,
  inventories: fetchInventoriesReducer,
  dispute: fetchDisputeReducer,
  disputes: fetchDisputesReducer,
  address: fetchAddressReducer,
  addresses: fetchAddressesReducer,
  import_products: fetchImportProductsReducer,
  withdrawal: fetchWithdrawalReducer
})
