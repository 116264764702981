import {
  FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_FAILED
} from '../../types';

const initialState = {
  subscriptions: {},
  isLoading: false,
  errors: {},
  error: false
}

const fetchSubscriptionsReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        isLoading: true,
          subscriptions: action.payload
      }
      case FETCH_SUBSCRIPTIONS_SUCCESS:
        return {
          ...state,
          isLoading: false,
            subscriptions: action.payload
        }
        case FETCH_SUBSCRIPTIONS_FAILED:
          return {
            ...state,
            isLoading: false,
              errors: action.payload,
              error: true
          }
          default:
            return {
              ...state
            };
  }
}

export default fetchSubscriptionsReducer;
