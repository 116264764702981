import React from "react";
import Layout from "../../components/Layout/Layout";
import photo from "../../images/photo.png";
import "./styles.scss";
import CardAdmin from "../../components/Card/CardAdmin";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";

const Rating = (props) => {
  return (
    <Layout>
      <section className="dash-section ">
      <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>

        <h4>Ratings</h4>

        <div className="row dash-card-info-3">
          <div className="col-md-12 ">
            <CardAdmin>
              <div className="d-flex justify-content-between">
                <p>All ratings</p>
                <span>
                  Sort by Type{" "}
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                </span>
              </div>
              <table class="table table-borderless table-hover table-striped table-responsive-sm">
                <thead>
                  <tr>
                    <th>Mercahnts</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Expires</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Naija Exclusive wears</td>
                    <td>Naija Exclusive wears</td>
                    <td>$39.94</td>
                    <td>05 / 01 / 2021</td>
                  </tr>
                  <tr>
                    <td>Naija Exclusive wears</td>
                    <td>Naija Exclusive wears</td>
                    <td>$39.94</td>
                    <td>05 / 01 / 2021</td>
                  </tr>
                  <tr>
                    <td>Naija Exclusive wears</td>
                    <td>Naija Exclusive wears</td>
                    <td>$39.94</td>
                    <td>05 / 01 / 2021</td>
                  </tr>
                  <tr>
                    <td>Naija Exclusive wears</td>
                    <td>Naija Exclusive wears</td>
                    <td>$39.94</td>
                    <td>05 / 01 / 2021</td>
                  </tr>
                </tbody>
              </table>
            </CardAdmin>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Rating;
