import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import photo from "../../images/photo.png";
import "./styles.scss";
import CardAdmin from "../../components/Card/CardAdmin";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import numberToComma from "../../utils/numberToComma";

import {
  fetchAllProducts,
  fetchPublishProduct,
  fetchSingleProduct,
  fetchInventory,
  fetchAddresses
} from "../../redux/actions/product";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalCard from "../../components/ModalCard/ModalCard";
import swal from "sweetalert";
import Axios from "axios";
import { BASE_URL } from "../../redux/constant";
import Auth from "../../config/auth";

const Product = (props) => {
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [invent, setInvent] = useState({});
  const [reviews, setReviews] = useState([]);
  const [form, setForm] = useState({});
  const [addresses, setAddresses] = useState([])
  const [variationItem, setVariationItem] = useState([])
  const [check, setCheck] = useState(false);
  const [page, setPage] = useState(1);


  //const [published, setPublished] = useState(false);
  const [pic, setPic] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalInven, setModalInven] = useState(false);
  const toggleInven = () => setModalInven(!modalInven);

  const [modalReview, setModalReview] = useState(false);
  const toggleReview = () => setModalReview(!modalReview);

  const addDefaultSrc = (e) => {
    e.target.src = `https://c8.alamy.com/comp/P2D424/store-vector-icon-isolated-on-transparent-background-store-logo-concept-P2D424.jpg`;
  };

  function next() {
    setPage(page + 1);
    props.fetchAllProducts(page);
  }

  function back() {
    setPage(page - 1);
    props.fetchAllProducts(page);
    
  }

  function viewProduct(data, pic) {
    //setProduct(data);
    //setPic(pic);
    setCheck(true)
    props.fetchSingleProduct(data.id);
    console.log(data);
    toggle();
  }

  function viewReview(id){
    review(id)
    toggleReview()
  }

  async function handlePublish(id,pub) {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.put(`${BASE_URL}/merchants/products/1585139305/${id}`,{published: pub},{headers} )
      if(response.data.hasError){
        swal("update not successfull","","error");
      } else {
        swal("update successfull","","success");
        props.fetchAllProducts(page);
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function review(id) {
    try {
      const response = await Axios.get(`${BASE_URL}/products/review/${id}`)
      console.log(response)
      setReviews(response.data.data.reviews)
    } catch (error) {
      console.log(error)
    }
  }

  function inventory(data) {
    setInvent(data);
    setCheck(true)
    props.fetchSingleProduct(data.id);
    toggleInven();
  }

  // function editProduct(data) {
  //   setProduct(data);
  //   toggleEdit();
  // }

  function publish(id, pub) {
    props.fetchPublishProduct(id, { published: pub });
    toggle();
  }

  function addInventory() {
    setCheck(true);
    props.fetchInventory(form);
    toggleInven();
  }

  useEffect(() => {
    if (props.inventory.inventory.data && check) {
      swal(props.inventory.inventory.message, "", "success");
      setCheck(false);
    }
  }, [props.inventory.inventory]);

  useEffect(() => {
    if (props.addresses.addresses.data) {
      setAddresses(props.addresses.addresses.data.store_address)
    }
  }, [props.addresses.addresses]);

  useEffect(() => {
    props.fetchAllProducts();
    props.fetchAddresses();
  }, []);

  useEffect(() => {
    if (props.products.products.data) {
      setProducts(props.products.products.data.products);
    }
  }, [props.products.products.data?.products]);

  useEffect(() => {
    if (props.product.product.data && check) {
      const { product, store } = props.product.product.data;
      setProduct(product)
       setPic(product.images[0]);
      setVariationItem(product.product_variations)
      setForm({
        ...form,
        variation_id: product.hasVariation
          ? product.product_variations[0].id
          : null,
        product_id: product.id,
        name: product.product_name,
        store_id: store.id,
        //address_id: 2,
      });
      setCheck(false)
    }
  }, [props.product.product]);

  return (
    <Layout>
      <section className="dash-section ">
        <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <div className="row dash-card-info-3">
          <div className="col-md-12 ">
            <Link to="/add-product">
              <button className="btn btn-block product-btn mb-3">
                <p> + </p>
                <p>Add New Product</p>
              </button>
            </Link>
          
              <table class="table table-borderless table-hover table-striped table-responsive-sm">
                <thead>
                  <tr>
                    <th>Market place</th>
                    <th></th>
                    <th className="prod-admin-subtitle ">
                      for September 2020
                      <span>
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(products || []).map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <img
                            className="admin-profile-container"
                            src={item.images[0]}
                            alt="admin"
                            width="30%"
                            onError={addDefaultSrc}
                          />
                        </td>
                        <td>
                          <div>
                            <p className="dash-info-title pb-3"  onClick={() => window.open(`https://marketplace-sandbox.shobbu.com/product/${item.product_slug}`,'_blank')}>
                              {item.product_name}
                            </p>
                            {/* <p className="dash-info-subtitle">
                              Customer: Ayodeji Osindele{" "}
                            </p> */}
                            <p className="dash-info-subtitle">
                              Stock: {item.stock_limit}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="dash-info-subtitle pb-3">view</p>
                            <p className="dash-info-title">
                              ₦ {numberToComma(item.net_price)}
                            </p>
                          </div>
                        </td>
                        <td>
                          <button
                            className="m-2 btn btn-sm btn-outline-info"
                            onClick={() => viewProduct(item, item.images[0])}
                          >
                            view
                          </button>
                          <button
                            className="m-2 btn btn-sm btn-outline-secondary"
                            onClick={() =>
                              history.push(`/edit-product/${item.id}`)
                            }
                          >
                            edit
                          </button>
                          <button
                            className="m-2 btn btn-sm btn-outline-primary"
                            onClick={() => inventory(item)}
                          >
                            add inventory
                          </button>
                          <button
                            className="m-2 btn btn-sm btn-outline-warning"
                            onClick={() => viewReview(item.id)}
                          >
                            reviews
                          </button>
                        </td>
                        <td> <label class="switch ml-2">
                    <input
                      type="checkbox"
                      checked={item.published}
                      value={item.published}
                      onChange={() => handlePublish(item.id, !item.published)}
                    />
                    <span class="slider round"></span>
                  </label></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            
            <ModalCard
              title={product.product_name}
              toggle={toggle}
              modal={modal}
            >
              <div className="d-flex justify-content-around pb-3">
                <img src={pic} alt="product" width="40%"  onError={addDefaultSrc} />
                <div>
                  <p>Publish</p>
                  <label class="switch ml-2">
                    <input
                      type="checkbox"
                      checked={product.published}
                      value={product.published}
                      onChange={() => publish(product.id, !product.published)}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <p>
               <strong >Name: </strong>{product.product_name}
              </p>
              <p>
              <strong>Price : </strong>{numberToComma(product?.net_price ? product?.net_price : '')}
              </p>
              <p>
              {!product.hasVariation && (<strong>Stock: {product.stock_limit}</strong>)}
              </p>

              {product.hasVariation && (
                <table className="table table-borderless table-striped table-hover">
                  <th>variation</th>
                  <th>price</th>
                  <th>quantity</th>
                  <th>discount</th>
                  <tbody>
                    {product?.product_variations.map((item,i)=>{
                      return(
                        <tr>
                          <td>{item.variation}</td>
                      <td>{item.price}</td>
                      <td>{item.quantity}</td>
                      <td> {item.discount_type === 'value' && '₦'} {item.discount} {item.discount_type === 'percent' && '%'}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}

            </ModalCard>
            <ModalCard title="Review" toggle={toggleReview} modal={modalReview}>
              <table className="table table-borderless table-striped table-hover">
                <tbody>
                {reviews.length < 1 && <h6>No reviews yet</h6>}
                {(reviews || []).map((item,i)=>{
                      return(
                        <tr>
                          <td><img src={item.user.profile_pic_url}alt="admin"
                            width="70px" height="auto"/></td>
                          <td>{item.user.full_name}</td>
                      <td>{item.review}</td>
                      <td> <span>{item.star} <i class="fa fa-star" aria-hidden="true"></i></span> </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </ModalCard>
            <ModalCard
              title="Inventory Add"
              toggle={toggleInven}
              modal={modalInven}
            >
              <p>{invent?.product_name}</p>
              <img src={pic} width="30%" height="150px" alt="pic"/>
              <form className="form pt-3">
                <div className="row">
                <div className="input-group mb-3 col-md-12">
                    <select
                      className="custom-select"
                      id="inputGroupSelect01"
                      //value={form.store_id}
                      onChange={(e) =>
                        setForm({ ...form, variation_id: e.target.value })
                      }
                    >
                      <option selected>Select variation ...</option>
                      {(variationItem || []).map((index, i) => {
                        return (
                          <option key={i} value={index.id}>
                            {index.variation}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="enter quantity"
                    onChange={(e) =>
                      setForm({ ...form, quantity: e.target.value })
                    }
                  />
                </div>
                <div className="row">
                  <div className="input-group mb-3 col-md-12">
                    <select
                      className="custom-select"
                      id="inputGroupSelect01"
                      //value={form.store_id}
                      onChange={(e) =>
                        setForm({ ...form, address_id: e.target.value })
                      }
                    >
                      <option selected>Select address ...</option>
                      {(addresses || []).map((index, i) => {
                        return (
                          <option key={i} value={index.id}>
                            {index.name} : {index.line1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </form>
              <button className="btn btn-primary" onClick={addInventory}>
                add
              </button>
            </ModalCard>
          </div>
        </div>
        <div>
          <button className="btn btn-sm btn-outline-secondary mr-2" disabled={page < 2 ? true : false} onClick={back}>back</button>
          <button className="btn btn-sm btn-outline-secondary" onClick={next}>next</button>
        </div>
      </section>
    </Layout>
  );
};

Product.propTypes = {
  products: PropTypes.object.isRequired,
  fetchAllProducts: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  category: state.category,
  media: state.media,
  products: state.products,
  product: state.product,
  inventory: state.inventory,
  addresses: state.addresses
});

const pageActions = {
  fetchAllProducts,
  fetchPublishProduct,
  fetchSingleProduct,
  fetchInventory,
  fetchAddresses
};

export default connect(mapStateToProps, pageActions)(Product);
