import React, { useState, Fragment } from "react";
import Layout from "../../components/Layout/Layout";
import photo from "../../images/photo.png";
import "./styles.scss";
import CardAdmin from "../../components/Card/CardAdmin";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import numberToComma from "../../utils/numberToComma";

import {
  fetchOrders,
  fetchProcessOrders,
  fetchCancelOrders,
  fetchDisputes,
} from "../../redux/actions/order";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useEffect } from "react";
import swal from "sweetalert";
import myDate from "../../utils/myDate";
import ModalCard from "../../components/ModalCard/ModalCard";

import Axios from "axios";
import { BASE_URL } from "../../redux/constant";
import Auth from "../../config/auth";

const Orders = (props) => {
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState({});
  const [form, setForm] = useState({});
  const [disputes, setDisputes] = useState([]);
  const [status, setStatus] = useState({});
  const [check, setCheck] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalDispute, setModalDispute] = useState(false);
  const toggleDispute = () => setModalDispute(!modalDispute);
  const [report, setReport] = useState({ dispute: "", options: "", reply: "" });

  function viewOrder(data) {
    setOrder(data);
    setStatus({ status: data.order_proccessing_status });
    toggle();
  }

  function filter(e) {
    props.fetchOrders(e.target.value);
  }

  function processOrder(e) {
    e.preventDefault();
    setCheck(true);
    props.fetchProcessOrders(order.id, status);
    toggle();
  }

  function cancelOrder(id) {
    setCheck(true);
    props.fetchCancelOrders(id);
  }

  function viewDispute(data) {
    setOrder(data);
    props.fetchDisputes(data.id);
    toggleDispute();
  }

  async function reply(e) {
    e.preventDefault()
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = { message: report.reply };
    try {
      const response = await Axios.post(
        `${BASE_URL}/orders/thread/${disputes.id}`,
        data,
        { headers }
      );
      if (response.data.hasError) {
        swal(response.data.message,"","error")
      } else {
        props.fetchDisputes(disputes.order_id);
        setReport({ ...report,reply: "" });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    props.fetchOrders();
  }, []);

  useEffect(() => {
    if (props.orders.orders.data) {
      setOrders(props.orders.orders.data.orders);
    }
    if (check) {
      swal("Created successfully", "", "success");
      setForm({});
      setCheck(false);
    }
  }, [props.orders.orders]);

  useEffect(() => {
    if (check && props.orders.errors.errors) {
      swal(props.orders.errors.errors.message, "", "error");
      setForm({});
      setCheck(false);
    }
  }, [props.orders.errors.errors]);

  useEffect(() => {
    if (check && props.order.order.data) {
      swal(props.order.order.message, "", "success");
      setForm({});
      setStatus({});
      setCheck(false);
    }
  }, [props.order.order]);

  useEffect(() => {
    if (check && props.order.errors.errors) {
      swal(props.order.errors.errors.message, "", "error");
      setForm({});
      setStatus({});
      setCheck(false);
    }
  }, [props.order.errors.errors]);

  useEffect(() => {
    if (props.disputes.disputes.data) {
      setDisputes(props.disputes.disputes.data.dispute);
    }
  }, [props.disputes.disputes]);

  return (
    <Layout>
      <section className="dash-section">
        <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>

        <h3>Orders</h3>

        <label htmlFor="">Filter orders</label>
        <div className="input-group mb-3 col-md-3">
          <select
            className="custom-select"
            id="inputGroupSelect01"
            //value={form.filter}
            onChange={filter}
          >
            <option selected>all</option>
            <option>incomming</option>
            <option>dispatched</option>
            <option>completed</option>
            <option>cancelled</option>
          </select>
        </div>
        <div className="table-responsive ">
          <table className="table table-borderless table-striped table-hover">
            <thead>
              <tr>
                <th>Order No.</th>
                <th>Price</th>
                <th>Phone</th>
                <th>Status</th>
                <th>No of Item</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {(orders || []).map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.id}</td>
                    <td>{numberToComma(item.net_price)}</td>
                <td>{item.address.phone}</td>
                    <td>
                      {item.order_proccessing_status !== "cancelled" ? (
                        <span className="badge badge-success">
                          {item.order_proccessing_status}
                        </span>
                      ) : (
                        <span className="badge badge-danger">
                          {item.order_proccessing_status}
                        </span>
                      )}
                    </td>
                    <td>{item.items.items.length}</td>

                    <td>{myDate(item.updatedAt)}</td>
                    <td>
                      <button
                        onClick={() => viewOrder(item)}
                        className="btn btn-sm btn-info m-1"
                      >
                        <i class="fa fa-eye" aria-hidden="true"></i> view
                      </button>

                      <button
                        onClick={() => viewDispute(item)}
                        className="btn btn-sm btn-warning m-1"
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i> dispute
                      </button>

                      <button
                        onClick={() => cancelOrder(item.id)}
                        className="btn btn-sm btn-danger m-1"
                        disabled={
                          item.order_proccessing_status === "paid"
                            ? false
                            : true
                        }
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i> cancel
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <ModalCard
          title={`Order # ${order.id} Items`}
          toggle={toggle}
          modal={modal}
        >
          <p className="large">
            current status: <strong>{order.order_proccessing_status}</strong>
          </p>
          <form className="form-inline m-3">
            <div className="input-group col-md-9 mr-2">
              <select
                className="form-control"
                value={status.status}
                onChange={(e) =>
                  setStatus({ ...status, status: e.target.value })
                }
              >
                <option selected>select status</option>

                {status.status === "paid" && <option>processing</option>}
                {status.status === "processing" && <option>dispatched</option>}
                {status.status === "dispatched" && (
                  <Fragment>
                    <option>delivered</option>
                    <option>received</option>
                    <option>cancelled</option>
                    <option>refunded</option>
                  </Fragment>
                )}
                {status.status === "delivered" && (
                  <Fragment>
                    <option>received</option>
                    <option>cancelled</option>
                    <option>refunded</option>
                  </Fragment>
                )}
                {status.status === "received" && (
                  <Fragment>
                    <option>refunded</option>
                  </Fragment>
                )}
                {/* {(status.status === "cancelled") && (
                  <Fragment>
                    <option>received</option>
                    <option>cancelled</option>
                    <option>refunded</option>
                  </Fragment>
                )} */}
              </select>
            </div>
            <button
              type="button"
              onClick={processOrder}
              className="btn btn-sm btn-primary"
            >
              update
            </button>
          </form>
          {console.log(order.items ? order.items.items : [])}
          <div className="alert alert-secondary">
            {order.address?.name}
            <br />
            {order.address?.email}
            <br />
            {order.address?.phone}
            <br />
            {order.address?.line1}
          </div>
          <div className="table-responsive ">
            <table className="table">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Status</th>
                  <th>Delivered</th>
                </tr>
              </thead>
              <tbody>
                {(order.items ? order.items.items : []).map((item, i) => {
                  return (
                    <tr>
                      <td>
                        <img
                          className="admin-profile-container"
                          src={
                            item.product.images[0] === null
                              ? "https://c8.alamy.com/comp/P2D424/store-vector-icon-isolated-on-transparent-background-store-logo-concept-P2D424.jpg"
                              : item.product.images[0]
                          }
                          alt="admin"
                          width="20%"
                          onError={`https://c8.alamy.com/comp/P2D424/store-vector-icon-isolated-on-transparent-background-store-logo-concept-P2D424.jpg`}
                        />
                      </td>

                      <td className="cursor" onClick={() => window.open(`https://marketplace-sandbox.shobbu.com/product/${item.product.product_slug}`,'_blank')}>
                        {item.product.product_name} <br />{" "}
                        {item.has_variation && (
                          <strong style={{ fontSize: 12 }}>
                            {item.selected_variation.variation}
                          </strong>
                        )}{" "}
                      </td>
                      <td>{numberToComma(item.total_price)} </td>
                      <td>{item.quantity} </td>
                      <td>
                        <strong>{order.order_proccessing_status}</strong>
                      </td>
                      <td>{myDate(order.createdAt)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="">
              <table className="table table-borderless alert alert-secondary">
                <tr>
                  <td className="text-left summary-total">sub total :</td>
                  <td className="text-right">
                    ₦ {order ? order.price : "no price"}
                  </td>
                </tr>
                <tr>
                  <td className="text-left summary-total">Shipping fee :</td>
                  <td className="text-right">
                    {order ? "₦ " + order.logistics_value : "no fee"}
                  </td>
                </tr>
                <tr>
                  <td className="text-left summary-total">
                    Tax ({order ? order.tax_value : "no tax"}%):
                  </td>
                  <td className="text-right">
                    {" "}
                    -₦{" "}
                    {order ? (order.tax_value / 100) * order.price : "no tax"}
                  </td>
                </tr>
                <tr>
                  <td className="text-left summary-total">Discount:</td>
                  <td className="text-right">
                    -₦ {order ? order.discount_value : "no discount"}
                  </td>
                </tr>
                <tr>
                  <td className="text-left summary-total">Coupon discount:</td>
                  <td className="text-right text-danger">
                    {order
                      ? order.coupon_value != null
                        ? (order.coupon_type === "value" && "-₦") +
                          order.coupon_value +
                          (order.coupon_type === "percent" && "%") +
                          " - (" +
                          order.coupon_id +
                          ")"
                        : "no coupon"
                      : "no coupon"}
                  </td>
                </tr>
                <tr>
                  <td className="text-left summary-total">Total:</td>
                  <td className="text-right">
                    <h4>₦ {order ? order.net_price : "no price"}</h4>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </ModalCard>
        <ModalCard
          title={`Order # ${order.id}`}
          toggle={toggleDispute}
          modal={modalDispute}
        >
          {disputes === null ? (
            <h6>No dispute opened</h6>
          ) : (
            <Fragment>
              <h6>{disputes.dispute }</h6>
              <p>
                Status :{" "}
                <strong
                  className={
                    disputes.status === "open" ? `text-success` : `text-danger`
                  }
                >
                  {disputes.status}
                </strong>
              </p>
              {(disputes.dispute_threads || []).map((message, i) => {
                return (
                  <div
                    key={i}
                    className={
                      message.role === "you"
                        ? `alert alert-info text-right ml-5`
                        : `alert alert-secondary text-left mr-5`
                    }
                  >
                    {message.message}
                    <br />
                    <small>
                      {dayjs(message.createdAt).format("MMMM D, YYYY h:mm A")}
                    </small>
                  </div>
                );
              })}
              <form class="form-inline mb-2">
                       
                       <input
                         type="text"
                         className="form-control col-md-10 mb-1 mr-2"
                         id="inputPassword2"
                         placeholder="reply message"
                         value={report.reply}
                         onChange={(e) =>
                           setReport({ ...report, reply: e.target.value })
                         }
                       />
                    
                     <button
                       className="btn btn-outline-primary btn-sm"
                       onClick={reply}
                     >
                       send
                     </button>
                   </form>
            </Fragment>
          )}
        </ModalCard>
      </section>
    </Layout>
  );
};

Orders.propTypes = {
  orders: PropTypes.object.isRequired,
  fetchOrders: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  orders: state.orders,
  order: state.order,
  disputes: state.disputes,
});

const pageActions = {
  fetchOrders,
  fetchProcessOrders,
  fetchCancelOrders,
  fetchDisputes,
};

export default connect(mapStateToProps, pageActions)(Orders);
