import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import photo from "../../images/photo.png";
import "./styles.scss";
import CardAdmin from "../../components/Card/CardAdmin";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import numberToComma from "../../utils/numberToComma";

import {
  fetchCoupons,
  fetchCoupon,
  fetchDeleteCoupon,
} from "../../redux/actions/coupon";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useEffect } from "react";
import swal from "sweetalert";

const Coupon = (props) => {
  const [coupons, setCoupons] = useState([]);
  const [modal, setModal] = useState(false);
  const [form, setForm] = useState({
    store_id: "1585139305",
    time_zone_offset: "+04",
  });
  const [check, setCheck] = useState(false);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    props.fetchCoupons();
  }, []);

  useEffect(() => {
    if (props.coupons.coupons.data) {
      setCoupons(props.coupons.coupons.data.coupons);
    }
    if (check) {
      swal("Created successfully", "", "success");
      setForm({});
      setCheck(false);
    }
  }, [props.coupons.coupons]);

  useEffect(() => {
    if (check && props.coupon.errors.errors) {
      swal(props.coupon.errors.errors.message, "", "error");
      setForm({});
      setCheck(false);
    }
    // if (props.coupons.coupons.data) {
    //   setCoupons(props.coupons.coupons.data.coupons);
    // }
  }, [props.coupon.errors.errors]);

  function addCoupon(e) {
    e.preventDefault();
    setCheck(true);
    props.fetchCoupon(form);
    setModal(!modal);
  }

  function deleteCoupon(id) {
    props.fetchDeleteCoupon(id);
  }

  return (
    <Layout>
      <section className="dash-section ">
        <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <h3 className="pt-3 pb-3">Coupons</h3>
        
          <button
            onClick={toggle}
            className="btn publish-btn  text-center mb-2"
          >
            <i class="fa fa-plus mr-2" aria-hidden="true"></i>
            Create coupon
          </button>
        

        
          <div className="table-responsive ">
            <table className="table table-borderless table-striped table-hover ">
              <thead>
                <tr>
                  <th>Coupon</th>
                  <th>Type</th>
                  <th>Value</th>
                  <th>Status</th>
                  <th>Quantity</th>
                  <th>Used</th>
                  <th>Expiry</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {(coupons || []).map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.id}</td>
                      <td>{item.coupon_type}</td>
                      <td>{numberToComma(item.coupon_value)}</td>
                      <td>
                        {item.valid ? (
                          <span className="badge badge-success">VALID</span>
                        ) : (
                          <span className="badge badge-danger">NOT VALID</span>
                        )}
                      </td>
                      <td>{item.coupon_quantity}</td>
                      <td>{item.quantity_used}</td>
                      <td>{item.end_date}</td>
                      <td>
                        <button
                          onClick={() => deleteCoupon(item.id)}
                          className="btn btn-sm btn-danger"
                        >
                          remove
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        

        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Add Coupon</ModalHeader>
          <ModalBody>
            <form className="form" onSubmit={addCoupon}>
              <div className="p-3">
                <div className="">
                  <div className="form-group mb-2">
                    <label htmlFor="">Title</label>
                    <input
                      type="text"
                      name="item"
                      className="form-control mr-3"
                      placeholder="coupon name"
                      value={form.coupon_name}
                      onChange={(e) =>
                        setForm({
                          ...form,
                          coupon_name: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>

                <div className="">
                  <div className="form-group mb-2">
                    <label htmlFor="">Type</label>
                    <div className="input-group mb-3">
                      <select
                        className="custom-select"
                        id="inputGroupSelect01"
                        value={form.type}
                        onChange={(e) =>
                          setForm({ ...form, type: e.target.value })
                        }
                      >
                        <option selected>Select coupon type</option>
                        <option>value</option>
                        <option>percent</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="form-group mb-2">
                    <label htmlFor="">Amount</label>
                    <input
                      type="number"
                      name="item"
                      className="form-control mr-3"
                      value={form.value}
                      onChange={(e) =>
                        setForm({
                          ...form,
                          value: parseInt(e.target.value),
                        })
                      }
                      required
                    />
                  </div>
                </div>
                <div className="">
                  <div className="form-group mb-2">
                    <label htmlFor="">Quantity</label>
                    <input
                      type="number"
                      name="item"
                      className="form-control "
                      value={form.coupon_quantity}
                      onChange={(e) =>
                        setForm({
                          ...form,
                          coupon_quantity: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>
                <div className="">
                  <div className="form-group mb-3">
                    <label htmlFor="">Start date</label>
                    <input
                      type="date"
                      name="item"
                      className="form-control mr-3"
                      value={form.start_date}
                      onChange={(e) =>
                        setForm({
                          ...form,
                          start_date: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>
                <div className="">
                  <div className="form-group mb-3">
                    <label htmlFor="">End date</label>
                    <input
                      type="date"
                      name="item"
                      className="form-control mr-3"
                      value={form.end_date}
                      onChange={(e) =>
                        setForm({
                          ...form,
                          end_date: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={addCoupon}>
              create
            </Button>
          </ModalFooter>
        </Modal>
      </section>
    </Layout>
  );
};

Coupon.propTypes = {
  coupon: PropTypes.object.isRequired,
  fetchCoupons: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  coupon: state.coupon,
  coupons: state.coupons,
});

const pageActions = {
  fetchCoupons,
  fetchCoupon,
  fetchDeleteCoupon,
};

export default connect(mapStateToProps, pageActions)(Coupon);
