import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import photo from "../../images/photo.png";
import "./styles.scss";
import CardAdmin from "../../components/Card/CardAdmin";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import { Link, useHistory } from "react-router-dom";

import Axios from "axios";
import { BASE_URL } from "../../redux/constant";
import Auth from "../../config/auth";
import { useEffect } from "react";
import numberWithCommas from "../../utils/numberToComma";
import swal from "sweetalert";
import dayjs from "dayjs";

const SoldOut = (props) => {
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [variation, setVariation] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const resProduct = await Axios.get(
        `${BASE_URL}/merchants/products/finished/1585139305`,
        { headers }
      );
      const resVariation = await Axios.get(`${BASE_URL}/merchants/variations/finished/1585139305`, {
        headers,
      });
      if (resProduct.data.hasError) {
        swal(resProduct.data.message, "", "error");
      } else {
        setProducts(resProduct.data.data.products);
        console.log(resProduct.data.data.products);
      }

      if (resVariation.data.hasError) {
        swal(resProduct.data.message, "", "error");
      } else {
        setVariation(resVariation.data.data.variations);
        console.log(resVariation.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Layout>
      <section className="dash-section ">
        <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <h4>Sold out</h4>
        <div className="row dash-card-info-3">
          <div className="col-md-6 ">
            <CardAdmin>
              <table class="table table-borderless table-hover  table-responsive-sm">
                <thead>
                  <tr>
                    <th>Products</th>
                    <th></th>
                    <th className="prod-admin-subtitle ">
                      sort by Ratings
                      <span>
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(products || []).map((product, i) => {
                    return (
                      <tr
                        // onClick={() =>
                        //   history.push(`/customer-profile/${customer.id}`)
                        // }
                      >
                        <td>
                          <img
                            className="admin-profile-container"
                            src={
                              product.images[0] === null
                                ? "https://aeealberta.org/wp-content/uploads/2018/10/profile.png"
                                : product.images[0]
                            }
                            alt="admin"
                            width="30%"
                          />
                        </td>

                        <td>
                          <div>
                            <p className="dash-info-title pb-3">
                              {product.product_name}
                            </p>
                            <p className="dash-info-subtitle">
                              Price:{" "}
                              {numberWithCommas(product.net_price)}
                            </p>
                            <p className="dash-info-subtitle">{product.category_title}</p>
                          </div>
                        </td>
                        {/* <td>
                          <div>
                            <p className="dash-info-subtitle pb-3">view</p>
                          </div>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardAdmin>
          </div>
          <div className="col-md-6">
            <CardAdmin>
              <table class="table table-borderless table-hover  ">
                <thead>
                  <tr>
                    <th>Variations</th>
                    <th></th>

                    <th className="prod-admin-subtitle ">
                      sort by Ratings
                      <span>
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(variation || []).map((variation, i) => {
                    return (
                      <tr
                        // onClick={() =>
                        //   history.push(`/store-profile/${store.id}`)
                        // }
                      >
                        <td>
                          <img
                            className="admin-profile-container"
                            src={
                              variation.product.images[0] === null
                                ? "https://c8.alamy.com/comp/P2D424/store-vector-icon-isolated-on-transparent-background-store-logo-concept-P2D424.jpg"
                                : variation.product.images[0]
                            }
                            alt="admin"
                            width="30%"
                            onError={`https://c8.alamy.com/comp/P2D424/store-vector-icon-isolated-on-transparent-background-store-logo-concept-P2D424.jpg`}
                          />
                        </td>

                        <td>
                          <div>
                            <p className="dash-info-title pb-3">
                              {variation.variation}
                            </p>
                            <p className="dash-info-subtitle">
                              Price: {" "}
                              {numberWithCommas(variation.net_price)}
                            </p>
                            <p className="dash-info-subtitle">{variation.product.category_title}</p>
                          </div>
                        </td>
                        {/* <td>
                          <div>
                            <p className="dash-info-subtitle pb-3">view</p>
                          </div>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardAdmin>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SoldOut;
