import React from "react";
import Layout from "../components/Layout/Layout";

const ErrorPage = (props) => {
  return (
    <Layout>
      <section className="">
        <div className="p-2 text-white">
          <div className="">
            <div className="text-center">
              <h1>404</h1>
              <h6>Page not found !</h6>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ErrorPage;
