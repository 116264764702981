import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import "./styles.scss";
import swal from "sweetalert";
import CardAdmin from "../../components/Card/CardAdmin";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import { fetchCategory } from "../../redux/actions/category";
import { fetchAllMedia, fetchUploadMedia } from "../../redux/actions/media";
import { fetchAddProduct } from "../../redux/actions/product";
import { fetchAddCategory } from "../../redux/actions/category";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "../../utils/isEmpty";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import ModalCard from "../../components/ModalCard/ModalCard";
import Axios from "axios";
import { BASE_URL } from "../../redux/constant";
import Auth from "../../config/auth";

const AddProduct = (props) => {
  const [category, setCategory] = useState([]);
  const [form, setForm] = useState({});
  const [images, setImages] = useState({});
  const [imagesUrl, setImagesUrl] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalPreview, setModalPreview] = useState(false);
  const [modalVariation, setModalVariation] = useState(false);
  const [modalCategory, setModalCategory] = useState(false);
  const [modalSubCat, setModalSubCat] = useState(false);
  const [variation, setVariation] = useState(false);
  const [variationItem, setVariationItem] = useState([]);
  const [variationObject, setvariationObject] = useState({});
  const [file, setFile] = useState();
  const [cat, setCat] = useState({});
  const [check, setCheck] = useState(false);
  const [catKey, setCatKey] = useState(0);
  const [subKey, setSubKey] = useState("");
  const fileObj = [];
  const fileArray = [];

  const toggle = () => setModal(!modal);
  const togglePreview = () => setModalPreview(!modalPreview);
  const toggleVariation = () => setModalVariation(!modalVariation);
  const toggleCategory = () => setModalCategory(!modalCategory);
  const toggleSubCat = () => setModalSubCat(!modalSubCat);

  useEffect(() => {
    props.fetchCategory();
    props.fetchAllMedia();
    setForm({ ...form, published: true, price: "", discount: "" });
  }, []);

  useEffect(() => {
    if (props.category.category.data) {
      setCategory(props.category.category.data.store.categories);
      if (check) {
        swal("category added");
      }
    }
  }, [props.category.category.data]);

  useEffect(() => {
    console.log(props.media.media.message);
    if (props.media.media.data) {
      setFile("");
      setCheck(false)
      setImages(props.media.media.data);
    }
  }, [props.media.media]);

  useEffect(() => {
    console.log(props.product);
    if (!isEmpty(props.product.product.message) && check) {
      //NotificationManager.success(props.product.product.message);
      swal(props.product.product.message, "", "success");
      setForm({
        product_name: "",
        description: "",
        specification: "",
        stock_limit: "",
        price: "",
        categoty_id: "",
        phone: "",
        discount:""
      });
      setCat({});
      setImagesUrl([]);
      setCheck(false);
    }
  }, [props.product.product]);

  useEffect(() => {
    console.log(props.product);
    if (!isEmpty(props.product.errors.errors) && check) {
      NotificationManager.error(props.product.errors.errors.message);
      setCheck(false);
    }
  }, [props.product.errors.errors]);

  function addProduct(e) {
    e.preventDefault();
    setCheck(true);
    if (!variation) {
      let data = {
        ...form,
        images: imagesUrl,
      };
      console.log(data);
      props.fetchAddProduct(data);
    } else {
      let data = {
        ...form,
        discount_type: variationItem[0].discount_type,
        discount: variationItem[0].discount,
        price: variationItem[0].price,
        images: imagesUrl,
        variation: variationItem,
      };

      console.log(data);
      props.fetchAddProduct(data);
    }
  }

  function handleSelect(e) {
    if (e.target.type === "checkbox" && !e.target.checked) {
      setImagesUrl(imagesUrl.filter((item) => item !== e.target.value));
    } else {
      setImagesUrl([...imagesUrl, e.target.value]);
    }
  }

  function removeImage(url) {
    let n = imagesUrl.includes(url);
    if (n) {
      setImagesUrl(imagesUrl.filter((item) => item !== url));
    }
  }

  function addVariation() {
    setVariationItem([...variationItem, variationObject]);
    setvariationObject({});
    setModalVariation(!modalVariation);
  }

  function removeVariation(key) {
    setVariationItem(variationItem.filter((item) => item !== key));
  }

  function uploadMultipleFiles(e) {
    setFile(e.target.files[0]);
  }

  function uploadFiles(e) {
    e.preventDefault();
    setCheck(true)
    const fd = new FormData();
    fd.append("images", file, "image1");
    props.fetchUploadMedia(fd);
    console.log(fd);
  }

  function addCat(e) {
    e.preventDefault();
    setCheck(true);
    props.fetchAddCategory({ name: cat.name, store_id: "1585139305", subCategories: cat.subs });
    setModalCategory(!modalCategory);
    console.log(cat);
  }

  async function addSubCat(e) {
    e.preventDefault();
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
   const data = {category_id: cat.category_id, store_id: "1585139305", subCategories: cat.subs }
    try {
      const response = await Axios.put(`${BASE_URL}/merchants/stores/category`,data,{headers});
      if(response.data.hasError){
        console.log(response.data)
      } else {
        swal(response.data.message,"","success")
        props.fetchCategory()
      }
    } catch (error) {
      console.log(error)
    }

    console.log(cat);
  }

  function handleCategory(e) {
    setForm({ ...form, categoty_id: category[e.target.value].id });
    setCatKey(e.target.value);
  }

  function handleSubCat(e) {
    setForm({ ...form, sub_category: e.target.value });
  }

  return (
    <Layout>
      <section className="dash-section ">
        <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <NotificationContainer />
        <div className="row dash-card-info-3">
          <div className="col-md-12 ">
       
             <h5>Add Product</h5>
              <form className="p-3 form" onSubmit={addProduct}>
                <div className="form-group mb-2">
                  <label htmlFor="">Product name</label>
                  <input
                    type="text"
                    name="item"
                    className="form-control product-input"
                    required
                    value={form.product_name}
                    onChange={(e) =>
                      setForm({ ...form, product_name: e.target.value })
                    }
                  />
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="">Description</label>

                  <textarea
                    type="text"
                    name="description"
                    className="form-control product-input"
                    required
                    value={form.description}
                    onChange={(e) =>
                      setForm({ ...form, description: e.target.value })
                    }
                    rows="3"
                  ></textarea>
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="">Specification</label>
                  <textarea
                    type="text"
                    name="description"
                    className="form-control product-input"
                    value={form.specification}
                    onChange={(e) =>
                      setForm({ ...form, specification: e.target.value })
                    }
                    rows="3"
                  ></textarea>
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="">Add tag</label>
                  <input
                    type="text"
                    name="tags"
                    className="form-control product-input"
                    value={form.tags}
                    onChange={(e) => setForm({ ...form, tags: e.target.value })}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="">Phone number</label>
                  <input
                    type="tel"
                    name="phone"
                    className="form-control product-input"
                    required
                    value={form.phone}
                    onChange={(e) =>
                      setForm({ ...form, phone: e.target.value })
                    }
                  />
                </div>
                {!variation && (
                  <div>
                    <div className="form-group mb-2">
                      <label htmlFor="">Stock Limit</label>
                      <input
                        type="number"
                        name="stock"
                        className="form-control product-input"
                        required
                        value={form.stock_limit}
                        onChange={(e) =>
                          setForm({ ...form, stock_limit: e.target.value })
                        }
                      />
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="">Price</label>
                      <input
                        type="number"
                        name="price"
                        className="form-control product-input"
                        required
                        value={form.price}
                        onChange={(e) =>
                          setForm({ ...form, price: e.target.value })
                        }
                      />
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6  mb-3">
                        <input
                          type="number"
                          name="discount"
                          placeholder="discount %"
                          className="form-control product-input"
                          value={form.discount}
                          onChange={(e) =>
                            setForm({ ...form, discount: e.target.value })
                          }
                        />
                      </div>

                      <div className="col-md-6 row">
                        <label htmlFor="tax">Charge taxes</label>
                        <label class="switch ml-2">
                          <input type="checkbox" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>

                    <label htmlFor="">Discount type</label>
                    <div className="input-group mb-3">
                      <select
                        className="custom-select product-select"
                        id="inputGroupSelect01"
                        value={form.discount_type}
                        onChange={(e) =>
                          setForm({ ...form, discount_type: e.target.value })
                        }
                      >
                        <option selected>Select discount type</option>
                        <option>value</option>
                        <option>percent</option>
                      </select>
                    </div>
                  </div>
                )}
                <label htmlFor="">Category</label>
                <div className="row">
                  <div className="input-group mb-3 col-md-10">
                    <select
                      className="custom-select product-select"
                      id="inputGroupSelect01"
                      value={catKey}
                      onChange={handleCategory}
                    >
                      <option selected>Select Categories...</option>
                      {category.map((index, i) => {
                        return (
                          <option key={i} value={i}>
                            {index.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-2">
                    <p
                      onClick={toggleCategory}
                      className="btn btn-outline-secondary  text-center pt-2"
                    >
                      add new
                    </p>
                  </div>
                </div>

                <label htmlFor="">Sub Category</label>
                <div className="row">
                  <div className="input-group mb-3 col-md-10">
                    <select
                      className="custom-select product-select"
                      id="inputGroupSelect01"
                      onChange={handleSubCat}
                    >
                      <option selected>Select Sub Categories...</option>
                      {(
                        (category.length > 1 &&
                          category[catKey].subCategories) ||
                        []
                      ).map((name, i) => {
                        return (
                          <option key={i} value={name}>
                            {name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-2">
                    <p
                      onClick={toggleSubCat}
                      className="btn btn-outline-secondary  text-center pt-2"
                    >
                      add new
                    </p>
                  </div>
                </div>

                <div className="variation-container">
                  <div className="p-2">
                    <i
                      onClick={toggle}
                      className="fa fa-camera fa-4x variation-images"
                      aria-hidden="true"
                    ></i>
                  </div>
                  {(imagesUrl || []).map((url, i) => (
                    <div className="p-2" key={i}>
                      <img
                        className="variation-images"
                        src={url}
                        width="110px"
                        height="80px"
                        alt="variation"
                      />
                      <i
                        onClick={() => removeImage(url)}
                        className="fa fa-trash"
                        aria-hidden="true"
                      ></i>
                    </div>
                  ))}
                </div>

                <div className="mb-3">
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Select image</ModalHeader>
                    <ModalBody>
                      <div>
                        <div>
                          <form>
                            <div className="form-group">
                              <input
                                type="file"
                                className="form-control"
                                onChange={uploadMultipleFiles}
                                multiple
                              />
                            </div>
                            <button
                              type="button"
                              className="btn btn-danger btn-block"
                              onClick={uploadFiles}
                              disabled={check}
                            >
                              {check ? 'Uploading' : 'Upload'}
                            </button>
                          </form>
                        </div>

                        <div className="row">
                          {(images.media || []).map((url, i) => (
                            <div className="p-2" key={i}>
                              <input
                                name="images"
                                type="checkbox"
                                value={url.media_url}
                                checked={imagesUrl.includes(url.media_url)}
                                onChange={handleSelect}
                              />
                              <img
                                src={url.media_url}
                                width="100px"
                                height="100px"
                                alt="..."
                                className="variation-images"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={toggle}>
                        ok
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>

                {/* <label htmlFor="">Delivery</label>
                <div className="input-group mb-3">
                  <select
                    className="custom-select product-select"
                    id="inputGroupSelect01"
                  >
                    <option selected>Select logistics</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div> */}

                <div className="pt-2 d-flex justify-content-between">
                  <label htmlFor="tax">Publish product</label>
                  <label class="switch ml-2">
                    <input
                      type="checkbox"
                      checked={form.published}
                      value={form.published}
                      onChange={() =>
                        setForm({ ...form, published: !form.published })
                      }
                    />
                    <span class="slider round"></span>
                  </label>
                </div>

                <div className=" pt-2 d-flex justify-content-between">
                  <label htmlFor="variation">Product variation</label>
                  <label class="switch ml-2">
                    <input
                      type="checkbox"
                      checked={variation}
                      value={variation}
                      onChange={() => {
                        setVariation(!variation);
                        if (!variation) {
                          setModalVariation(!modalVariation);
                        }
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>

                {variation && (
                  <div className="variation-container">
                    <div>
                      <p
                        onClick={toggleVariation}
                        className="btn btn-outline-primary  text-center pt-2"
                      >
                        add new
                      </p>
                    </div>
                    {(variationItem || []).map((item, i) => (
                      <div
                        className="p-2 m-2 variation-items d-flex justify-content-between"
                        key={i}
                      >
                        <div>
                          <p>
                            Variation : <strong> {item.variation}</strong>
                          </p>
                          <p>
                            Quantity : <strong>{item.quantity}</strong>
                          </p>
                          <p>
                            Price : <strong>{item.price}</strong>
                          </p>
                          <p>
                            Discount :{" "}
                            <strong>
                              {item.discount_type === "value" && "₦"}{" "}
                              {item.discount}{" "}
                              {item.discount_type === "percent" && "%"}{" "}
                            </strong>
                          </p>
                        </div>
                        <div className="float-right">
                          <i
                            className="fa fa-trash text-danger"
                            aria-hidden="true"
                            onClick={() => removeVariation(item)}
                          ></i>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <p
                  className="btn btn-block btn-outline-secondary  text-center pt-2"
                  onClick={togglePreview}
                >
                  preview
                </p>
                <button
                  type="submit"
                  className="btn btn-block publish-btn  text-center pt-2"
                  disabled={check}
                >
                 {check ? 'Publishing product' : 'Publish product'}
                </button>
              </form>
          
          </div>
          <Modal isOpen={modalVariation} toggle={toggleVariation}>
            <ModalHeader toggle={toggleVariation}>Add variation</ModalHeader>
            <ModalBody>
              <form className="form">
                <div className="p-3">
                  <div className="">
                    <div className="form-group mb-2">
                      <label htmlFor="">Variation</label>
                      <input
                        type="text"
                        name="item"
                        className="form-control mr-3"
                        placeholder="S,M,XL,XXL"
                        value={variationObject.variation}
                        onChange={(e) =>
                          setvariationObject({
                            ...variationObject,
                            variation: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="">
                    <div className="form-group mb-2">
                      <label htmlFor="">Quantity</label>
                      <input
                        type="number"
                        name="item"
                        className="form-control mr-3"
                        value={variationObject.quantity}
                        onChange={(e) =>
                          setvariationObject({
                            ...variationObject,
                            quantity: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-2">
                      <label htmlFor="">Price</label>
                      <input
                        type="number"
                        name="item"
                        className="form-control "
                        value={variationObject.price}
                        onChange={(e) =>
                          setvariationObject({
                            ...variationObject,
                            price: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group mb-3">
                      <label htmlFor="">Discount</label>
                      <input
                        type="number"
                        name="item"
                        className="form-control mr-3"
                        value={variationObject.discount}
                        onChange={(e) =>
                          setvariationObject({
                            ...variationObject,
                            discount: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <label htmlFor="">Discount type</label>
                  <div className="input-group mb-3">
                    <select
                      className="custom-select"
                      id="inputGroupSelect01"
                      value={variationObject.discount_type}
                      onChange={(e) =>
                        setvariationObject({
                          ...variationObject,
                          discount_type: e.target.value,
                        })
                      }
                    >
                      <option selected>Select discount type</option>
                      <option>value</option>
                      <option>percent</option>
                    </select>
                  </div>
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={addVariation}>
                add variation
              </Button>
            </ModalFooter>
          </Modal>
          <div className="mb-3">
            <Modal isOpen={modalCategory} toggle={toggleCategory}>
              <ModalHeader toggle={toggleCategory}>Add category</ModalHeader>
              <ModalBody>
                <form className="form">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="enter category"
                      onChange={(e) => setCat({ ...cat, name: e.target.value })}
                    />
                     <input
                      type="text"
                      className="form-control"
                      placeholder="enter sub categories  e.g  Shirts, Pants, Underwears"
                      onChange={(e) => setCat({ ...cat, subs: e.target.value })}
                    />
                  </div>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={addCat}>
                  add
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div className="mb-3">
            <Modal isOpen={modalSubCat} toggle={toggleSubCat}>
              <ModalHeader toggle={toggleSubCat}>Add Sub Category</ModalHeader>
              <ModalBody>
                <form className="form">
                <div className="input-group mb-3 col-md-12">
                    <select
                      className="custom-select mb-2"
                      id="inputGroupSelect01"
                     // value={}
                      onChange={(e) => setCat({...cat, category_id: e.target.value})}
                    >
                      <option selected>Select Categories...</option>
                      {category.map((index, i) => {
                        return (
                          <option key={i} value={index.id}>
                            {index.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="enter sub categories  e.g  Shirts, Pants, Underwears"
                      onChange={(e) => setCat({ ...cat, subs: e.target.value })}
                    />
                  </div>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={addSubCat}>
                  add
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
        <ModalCard
          title="Subscription"
          toggle={togglePreview}
          modal={modalPreview}
        >
          <p>name: {form?.product_name}</p>
          <p>description: {form?.description}</p>
          <p>specification: {form?.specification}</p>
          <p>tags: {form?.tags}</p>
          <p>phone: {form?.phone}</p>
          <p>stock_limit: {form?.stock_limit}</p>
          <p>price: {form?.price}</p>
          <p>discount: {form?.discount}</p>
          <p>discount type: {form?.discount_type}</p>
        </ModalCard>
      </section>
    </Layout>
  );
};

AddProduct.propTypes = {
  category: PropTypes.object.isRequired,
  fetchCategory: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  category: state.category,
  media: state.media,
  product: state.product,
});

const pageActions = {
  fetchCategory,
  fetchAllMedia,
  fetchAddProduct,
  fetchUploadMedia,
  fetchAddCategory,
};

export default connect(mapStateToProps, pageActions)(AddProduct);
