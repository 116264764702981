import axios from 'axios';
import {
  FETCH_LOGIN,
  FETCH_LOGIN_FAILED,
  FETCH_LOGIN_SUCCESS,
} from '../types';
import {
  BASE_URL
} from '../constant';

import Auth from '../../config/auth'




export const fetchUserEmailLogin = (userData) => dispatch => {

  axios.post(`${BASE_URL}/users/login/email`, userData)
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        const {
          user,
          token
        } = response.data.data;
        Auth.setToken(token, user);
        dispatch({
          type: FETCH_LOGIN_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_LOGIN_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_LOGIN_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchUserProfile = () => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/users/profile/view`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        const {
          profile
        } = response.data.data;

        Auth.setToken(token, profile);
        dispatch({
          type: FETCH_LOGIN_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_LOGIN_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_LOGIN_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}
