import React, { useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import photo from "../../images/photo.png";
import "./styles.scss";
import CardAdmin from "../../components/Card/CardAdmin";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";

import { fetchAllInventory, fetchInventory } from "../../redux/actions/product";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalCard from "../../components/ModalCard/ModalCard";
import swal from "sweetalert";

import numberToComma from "../../utils/numberToComma";
import { useState } from "react";

const Inventory = (props) => {
  const [inventories, setInventories] = useState([]);

  useEffect(() => {
    props.fetchAllInventory();
  }, []);
  useEffect(() => {
    if (props.inventories.inventories.data) {
      setInventories(props.inventories.inventories.data.records);
    }
  }, [props.inventories.inventories]);

  return (
    <Layout>
      <section className="dash-section ">
        <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <h3>Inventory</h3>

        <div className="row dash-card-info-3">
          <div className="col-md-12 ">
            <CardAdmin>
              <table class="table table-borderless table-hover table-striped table-responsive-sm">
                <thead>
                  <tr>
                    <th>Market place</th>
                    <th></th>
                    <th className="prod-admin-subtitle ">
                      for September 2020
                      <span>
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(inventories || []).map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <img
                            className="admin-profile-container"
                            src={item.product.images[0]}
                            alt="admin"
                            width="30%"
                          />
                        </td>
                        <td>
                          <div>
                            <p className="dash-info-title pb-3">
                              {item.product.product_name}
                            </p>
                            <p className="dash-info-subtitle">
                              total: {item.quantity}
                            </p>
                            <p className="dash-info-subtitle">
                              variation: {item.product_variation?.variation}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="dash-info-subtitle pb-3">view</p>
                            <p className="dash-info-title">
                              ₦ {numberToComma(item.product.net_price)}
                            </p>
                          </div>
                        </td>
                        {/* <td>
                          <button
                            className="m-2 btn btn-sm btn-info"
                            // onClick={() => viewProduct(item, item.images[0])}
                          >
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          </button>
                          <button
                            className="m-2 btn btn-sm btn-secondary"
                            // onClick={() =>
                            //   history.push(`/edit-product/${item.id}`)
                            // }
                          >
                            <i className="fa fa-pencil" aria-hidden="true"></i>
                          </button>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardAdmin>
          </div>
        </div>
      </section>
    </Layout>
  );
};

Inventory.propTypes = {
  inventories: PropTypes.object.isRequired,
  fetchAllInventory: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  inventories: state.inventories,
});

const pageActions = {
  fetchAllInventory,
  fetchInventory,
};

export default connect(mapStateToProps, pageActions)(Inventory);
