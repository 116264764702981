import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  UncontrolledCollapse,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import logo from "../../images/logo.svg";

import Auth from "../../config/auth";
import "./styles.scss";
import Footer from "../Footer/Footer";

const sidebar = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <i className="fa fa-bars fa-lg pr-3" aria-hidden="true"></i>,
  },
  {
    path: "/product",
    name: "Products",
    icon: <i className="fa fa-cube fa-lg pr-3" aria-hidden="true"></i>,
    // icon2: <i className="fa fa-angle-down" aria-hidden="true"></i>,
  },
  {
    path: "/sold-out",
    name: "Sold out",
    icon: <i className="fa fa-cube fa-lg pr-3" aria-hidden="true"></i>,
    // icon2: <i className="fa fa-angle-down" aria-hidden="true"></i>,
  },
  {
    path: "/import-product",
    name: "Imported Products",
    icon: <i className="fa fa-cube fa-lg pr-3" aria-hidden="true"></i>,
    // icon2: <i className="fa fa-angle-down" aria-hidden="true"></i>,
  },
  {
    path: "/auction",
    name: "Auction",
    icon: <i className="fa fa-exchange fa-lg pr-3" aria-hidden="true"></i>,
    // icon2: <i className="fa fa-angle-down" aria-hidden="true"></i>,
  },
  {
    path: "/orders",
    name: "Orders",
    icon: <i class="fa fa-calendar-check-o fa-lg pr-3" aria-hidden="true"></i>,
  },
  {
    path: "/withdrawal",
    name: "Withdrawal",
    icon: <i class="fa fa-money fa-lg pr-3" aria-hidden="true"></i>,
  },
  {
    path: "/inventory",
    name: "Inventory",
    icon: <i class="fa fa-list fa-lg pr-3" aria-hidden="true"></i>,
  },
  {
    path: "/subscription",
    name: "Subscription",
    icon: <i class="fa fa-money fa-lg pr-3" aria-hidden="true"></i>,
  },
  {
    path: "/coupon",
    name: "Coupon",
    icon: <i class="fa fa-ticket fa-lg pr-3" aria-hidden="true"></i>,
  },
  {
    path: "/media",
    name: "Media",
    icon: <i className="fa fa-camera fa-lg pr-3" aria-hidden="true"></i>,
  },
  {
    path: "/profile",
    name: "Profile",
    icon: <i className="fa fa-user fa-lg pr-3" aria-hidden="true"></i>,
  },
  {
    path: "/rating",
    name: "Rating",
    icon: <i className="fa fa-star fa-lg pr-3" aria-hidden="true"></i>,
  },
  {
    path: "/transactions",
    name: "Transaction History",
    icon: <i className="fa fa-money fa-lg pr-3" aria-hidden="true"></i>,
  },
  {
    path: "/tax",
    name: "Tax",
    icon: <i className="fa fa-money fa-lg pr-3" aria-hidden="true"></i>,
  },
  {
    path: "/staff",
    name: "Staff",
    icon: <i className="fa fa-user fa-lg pr-3" aria-hidden="true"></i>,
  },
  {
    path: "/notification",
    name: "Notification",
    icon: <i className="fa fa-envelope fa-lg pr-3" aria-hidden="true"></i>,
  },
  {
    path: "/settings",
    name: "Setting",
    icon: <i className="fa fa-cogs fa-lg pr-3" aria-hidden="true"></i>,
   // icon2: <i className="fa fa-angle-down" aria-hidden="true"></i>,
  },
];

const Layout = (props) => {
  const [state, setState] = useState({});
  const { handleTog } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDrop = () => setDropdownOpen((prevState) => !prevState);

  let history = useHistory();
  let location = useLocation();

  function handleLogout() {
    Auth.destroyToken();
    history.go("/");
  }

  useEffect(() => {
    setState(Auth.getUser());
  }, []);

  return (
    <Fragment>
      <div className="d-flex" id="wrapper">
        <div
          className={`bg-light ${dropdownOpen ? "toggled" : ""}`}
          id="sidebar-wrapper"
        >
          <aside className="sidebar">
            <div className="logoimg text-center">
              <Link to="/dashboard">
                <img src={logo} alt="shobbu" />
              </Link>
            </div>
            <div className=" text-center">
              <div className="admin-profile">
                <img
                  className="admin-profile-container"
                  src={state?.profile_pic_url}
                  width="100%"
                  alt="dp"
                />
              </div>
              <br />
              <p>{state?.full_name}</p>
       
            </div>
            {sidebar.map((nav, i) => {
              return (
                <Link className="" to={nav.path} key={i}>
                  <p
                    className={
                      nav.path === location.pathname
                        ? "sidebar-nav-selected d-flex justify-content-between"
                        : "sidebar-nav d-flex justify-content-between"
                    }
                    id={nav.name === "Setting" ? "toggler" : ""}
                  >
                    <span>
                      {nav.icon}
                      {nav.name}
                    </span>
                    {nav.icon2}
                  </p>
                </Link>
              );
            })}
            {/* <UncontrolledCollapse className="pl-5" toggler="#toggler">
              <p className="sidebar-nav">
                <Link className="link" to="/dashboard">
                  Market
                </Link>
              </p>
              <p className="sidebar-nav">
                <Link className="link" to="/dashboard">
                  Merchant
                </Link>
              </p>
            </UncontrolledCollapse> */}
          </aside>
        </div>

        <div id="page-content-wrapper" className={`bg-light ${dropdownOpen ? "toggled" : ""}`}>
          <div className='fixed-nav'>
            <Navbar light expand="md navbar bg-light" className={`bg-light ${dropdownOpen ? "toggled" : ""}`}>
              <NavbarBrand>
                {/* <Link to="/dashboard">
              <img src={Logo} alt="shobbu" />
            </Link> */}
                <i
                  className="fa fa-bars"
                  aria-hidden="true"
                  onClick={toggleDrop}
                ></i>
              </NavbarBrand>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  <NavItem>
                    <NavLink>
                      <Link to="/dashboard">
                        {" "}
                        <Button className="sell" size="sm" color="primary">
                          Open site
                        </Button>{" "}
                      </Link>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink>
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle className="profile-container" nav>
                          <img
                            src={state?.profile_pic_url}
                            width="100%"
                            alt="profile"
                          />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem onClick={handleLogout}>
                            logout
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          </div>
          <div className="container-fluid" style={{marginTop: '8em'}}>{props.children}</div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Layout;
