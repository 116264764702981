import React from "react";
import "./styles.scss";

const Footer = (props) => {
  return (
    <footer className="footer">
      <ul className="nav justify-content-center light nav-footer">
        <li className="nav-item">
          <p className="p-2">
            Copyrights &copy; 2020 shobbu. All rights reserved{" "}
          </p>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
