import {
  FETCH_PRODUCT,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILED,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILED,
  FETCH_INVENTORY_SUCCESS,
  FETCH_INVENTORY_FAILED,
  FETCH_INVENTORIES_SUCCESS,
  FETCH_INVENTORIES_FAILED,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_FAILED,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_FAILED,
  FETCH_ADDRESS_SUCCESS,
  FETCH_ADDRESS_FAILED,
  FETCH_ADDRESSES_SUCCESS,
  FETCH_ADDRESSES_FAILED,
  FETCH_IMPORT_PRODUCTS_SUCCESS,
  FETCH_IMPORT_PRODUCTS_FAILED,
  FETCH_WITHDRAWAL_SUCCESS,
  FETCH_WITHDRAWAL_FAILED
} from '../types';
import axios from 'axios'

import {
  BASE_URL
} from '../constant';

import Auth from '../../config/auth'
// const token = Auth.getToken();
// const headers = {
//   Authorization: `Bearer ${token}`
// };

export const fetchAddProduct = (data) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.post(`${BASE_URL}/merchants/products/1585139305`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_PRODUCT_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_PRODUCT_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_PRODUCT_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchEditProduct = (id, data) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.put(`${BASE_URL}/merchants/products/1585139305/${id}`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch(fetchSingleProduct(id))
        // dispatch({
        //   type: FETCH_PRODUCT_SUCCESS,
        //   payload: response.data
        // })
      } else {
        dispatch({
          type: FETCH_PRODUCT_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_PRODUCT_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}



export const fetchPublishProduct = (id, data) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.put(`${BASE_URL}/merchants/products/1585139305/${id}`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch(fetchAllProducts());

      } else {
        dispatch({
          type: FETCH_PRODUCT_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_PRODUCT_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchAllProducts = (count) => dispatch => {
  //var page = 1 + (count ? count : 0);
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/merchants/products/all/1585139305?pages=${count || 1}&paginate=10`, token != null && ({
      headers
    }))
    .then(response => {
      console.log(response)
      if (!response.hasError) {
        dispatch({
          type: FETCH_PRODUCTS_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_PRODUCTS_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_PRODUCTS_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchSingleProduct = (id) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/products/viewone/${id}`, token != null && ({
      headers
    }))
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_PRODUCT_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_PRODUCT_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_PRODUCT_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchInventory = (data) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.post(`${BASE_URL}/inventory`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        //dispatch(fetchAllInventory())
        dispatch({
          type: FETCH_INVENTORY_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_INVENTORY_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_INVENTORY_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchAllInventory = () => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/inventory/1585139305`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_INVENTORIES_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_INVENTORIES_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_INVENTORIES_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchSubscription = () => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/subscription`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_SUBSCRIPTION_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_SUBSCRIPTION_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_SUBSCRIPTION_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchMerchantSubscriptions = () => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/subscription/admin/history?page=1&paginate=20`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_SUBSCRIPTIONS_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_SUBSCRIPTIONS_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_SUBSCRIPTIONS_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchPostSubscription = (data) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.post(`${BASE_URL}/subscription`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_SUBSCRIPTION_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_SUBSCRIPTION_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_SUBSCRIPTION_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchPostAddress = (data) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.post(`${BASE_URL}/merchants/address`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_ADDRESS_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_ADDRESS_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_ADDRESS_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchAddresses = () => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/merchants/address/1585139305`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_ADDRESSES_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_ADDRESSES_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_ADDRESSES_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchImportProducts = (filter) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/admin/move-product?approval=${filter}`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_IMPORT_PRODUCTS_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_IMPORT_PRODUCTS_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_IMPORT_PRODUCTS_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchActionImportProducts = (data) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.post(`${BASE_URL}/admin/move-product`,data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch(fetchImportProducts())
      } else {
        dispatch({
          type: FETCH_IMPORT_PRODUCTS_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_IMPORT_PRODUCTS_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchWithdrawal = () => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/transaction/admin/witdraw`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_WITHDRAWAL_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_WITHDRAWAL_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_WITHDRAWAL_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchPutWithdrawal = (data) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.put(`${BASE_URL}/transaction/admin/witdraw`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch(fetchWithdrawal())
      } else {
        dispatch({
          type: FETCH_WITHDRAWAL_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_WITHDRAWAL_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}