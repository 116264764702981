import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import "./styles.scss";
import swal from "sweetalert";
import CardAdmin from "../../components/Card/CardAdmin";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import { fetchCategory } from "../../redux/actions/category";
import { fetchAllMedia, fetchUploadMedia } from "../../redux/actions/media";
import { fetchPostAuction } from "../../redux/actions/auctions";
import { fetchAddCategory } from "../../redux/actions/category";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "../../utils/isEmpty";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Axios from "axios";
import { BASE_URL } from "../../redux/constant";
import Auth from "../../config/auth";

const AddAuction = (props) => {
  const [category, setCategory] = useState([]);
  const [form, setForm] = useState({});
  const [images, setImages] = useState({});
  const [imagesUrl, setImagesUrl] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalCategory, setModalCategory] = useState(false);
  const [modalSubCat, setModalSubCat] = useState(false);
  const [file, setFile] = useState();
  const [cat, setCat] = useState({});
  const [check, setCheck] = useState(false);
  const [catKey, setCatKey] = useState(0);

  const toggle = () => setModal(!modal);
  const toggleCategory = () => setModalCategory(!modalCategory);
  const toggleSubCat = () => setModalSubCat(!modalSubCat);

  useEffect(() => {
    props.fetchCategory();
    props.fetchAllMedia();
    //setForm({ ...form, published: true, price: "", discount: "" , time_zone_offset:"+01"});
    setForm({ ...form, time_zone_offset: "+01" });
  }, []);

  useEffect(() => {
    if (props.category.category.data) {
      setCategory(props.category.category.data.store.categories);
      if (check) {
        swal("cattegory added", "", "success");
      }
    }
  }, [props.category.category.data]);

  useEffect(() => {
    console.log(props.media.media.message);
    if (props.media.media.data) {
      setFile("");
      setImages(props.media.media.data);
    }
  }, [props.media.media]);

  useEffect(() => {
    // console.log(props.product);
    if (props.auction.auction.data && check) {
      swal(props.auction.auction.message, "", "success");
      setForm({});
      setCheck(false);
    }
  }, [props.auction.auction]);

  useEffect(() => {
    console.log(props.auction);
    if (props.auction.errors.errors && check) {
      swal(props.auction.errors.errors.message, "", "error");
      setCheck(false);
    }
  }, [props.auction.errors.errors]);

  function addAuction(e) {
    e.preventDefault();
    setCheck(true);
    let data = {
      ...form,
      images: imagesUrl,
    };
    console.log(data);
    props.fetchPostAuction(data);
  }

  function handleSelect(e) {
    if (e.target.type === "checkbox" && !e.target.checked) {
      setImagesUrl(imagesUrl.filter((item) => item !== e.target.value));
    } else {
      setImagesUrl([...imagesUrl, e.target.value]);
    }
  }

  function removeImage(url) {
    let n = imagesUrl.includes(url);
    if (n) {
      setImagesUrl(imagesUrl.filter((item) => item !== url));
    }
  }

  function uploadMultipleFiles(e) {
    setFile(e.target.files[0]);
  }

  function uploadFiles(e) {
    e.preventDefault();
    const fd = new FormData();
    fd.append("images", file, "image1");
    props.fetchUploadMedia(fd);
    console.log(fd);
  }

  function addCat(e) {
    e.preventDefault();
    setCheck(true);
    props.fetchAddCategory({
      name: cat.name,
      store_id: "1585139305",
      subCategories: cat.subs,
    });
    setModalCategory(!modalCategory);
    console.log(cat);
  }

  async function addSubCat(e) {
    e.preventDefault();
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = {
      category_id: cat.category_id,
      store_id: "1585139305",
      subCategories: cat.subs,
    };
    try {
      const response = await Axios.put(
        `${BASE_URL}/merchants/stores/category`,
        data,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        swal(response.data.message, "", "success");
        props.fetchCategory();
      }
    } catch (error) {
      console.log(error);
    }

    console.log(cat);
  }

  function handleCategory(e) {
    setForm({ ...form, categoty_id: category[e.target.value].id });
    setCatKey(e.target.value);
  }

  function handleSubCat(e) {
    setForm({ ...form, sub_category: e.target.value });
  }

  return (
    <Layout>
      <section className="dash-section ">
        <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <NotificationContainer />
        <div className="row dash-card-info-3">
          <div className="col-md-12 ">
            <CardAdmin>
              <p>Add Auction</p>
              <form className="p-3 form" onSubmit={addAuction}>
                <div className="form-group mb-2">
                  <label htmlFor="">Product name</label>
                  <input
                    type="text"
                    name="item"
                    className="form-control product-input"
                    required
                    value={form.product_name}
                    onChange={(e) =>
                      setForm({ ...form, product_name: e.target.value })
                    }
                  />
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="">Description</label>

                  <textarea
                    type="text"
                    name="description"
                    className="form-control product-input"
                    required
                    value={form.description}
                    onChange={(e) =>
                      setForm({ ...form, description: e.target.value })
                    }
                    rows="3"
                  ></textarea>
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="">Specification</label>
                  <textarea
                    type="text"
                    name="description"
                    className="form-control product-input"
                    value={form.specification}
                    onChange={(e) =>
                      setForm({ ...form, specification: e.target.value })
                    }
                    rows="3"
                  ></textarea>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="">Phone number</label>
                  <input
                    type="tel"
                    name="phone"
                    className="form-control product-input"
                    required
                    value={form.phone}
                    onChange={(e) =>
                      setForm({ ...form, phone: e.target.value })
                    }
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="">Price</label>
                  <input
                    type="number"
                    name="price"
                    className="form-control product-input"
                    required
                    value={form.price}
                    onChange={(e) =>
                      setForm({ ...form, price: e.target.value })
                    }
                  />
                </div>

                <div className="row">
                  <div className="form-group mb-3 col-md-3">
                    <label htmlFor="">Start Date</label>
                    <input
                      type="date"
                      name="startdate"
                      className="form-control product-input"
                      required
                      value={form.start_date}
                      onChange={(e) =>
                        setForm({ ...form, start_date: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group mb-3 col-md-3">
                    <label htmlFor="">End Date</label>
                    <input
                      type="date"
                      name="enddate"
                      className="form-control product-input"
                      required
                      value={form.end_date}
                      onChange={(e) =>
                        setForm({ ...form, end_date: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group mb-3 col-md-3">
                    <label htmlFor="">Start Time</label>
                    <input
                      type="time"
                      name="starttime"
                      className="form-control product-input"
                      required
                      value={form.start_time}
                      onChange={(e) =>
                        setForm({ ...form, start_time: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group mb-3 col-md-3">
                    <label htmlFor="">End Time</label>
                    <input
                      type="time"
                      name="endtime"
                      className="form-control product-input"
                      required
                      value={form.end_time}
                      onChange={(e) =>
                        setForm({ ...form, end_time: e.target.value })
                      }
                    />
                  </div>
                </div>

                <label htmlFor="">Category</label>
                <div className="row">
                  <div className="input-group mb-3 col-md-10">
                    <select
                      className="custom-select product-select"
                      id="inputGroupSelect01"
                      value={catKey}
                      onChange={handleCategory}
                    >
                      <option selected>Select Categories...</option>
                      {category.map((index, i) => {
                        return (
                          <option key={i} value={i}>
                            {index.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-2">
                    <p
                      onClick={toggleCategory}
                      className="btn btn-outline-secondary  text-center pt-2"
                    >
                      add new
                    </p>
                  </div>
                </div>

                <label htmlFor="">Sub Category</label>

                <div className="row">
                  <div className="input-group mb-3 col-md-10">
                    <select
                      className="custom-select product-select"
                      id="inputGroupSelect01"
                      onChange={handleSubCat}
                    >
                      <option selected>Select Sub Categories...</option>
                      {(
                        (category.length > 1 &&
                          category[catKey].subCategories) ||
                        []
                      ).map((name, i) => {
                        return (
                          <option key={i} value={name}>
                            {name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-2">
                    <p
                      onClick={toggleSubCat}
                      className="btn btn-outline-secondary  text-center pt-2"
                    >
                      add new
                    </p>
                  </div>
                </div>

                <label htmlFor="">Select images</label>
                <div className="variation-container">
                  <div className="p-2">
                    <i
                      onClick={toggle}
                      className="fa fa-camera fa-4x variation-images"
                      aria-hidden="true"
                    ></i>
                  </div>
                  {(imagesUrl || []).map((url, i) => (
                    <div className="p-2" key={i}>
                      <img
                        className="variation-images"
                        src={url}
                        width="110px"
                        height="80px"
                        alt="variation"
                      />
                      <i
                        onClick={() => removeImage(url)}
                        className="fa fa-trash"
                        aria-hidden="true"
                      ></i>
                    </div>
                  ))}
                </div>

                <div className="mb-3">
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Select image</ModalHeader>
                    <ModalBody>
                      <div>
                        <div>
                          <form>
                            <div className="form-group">
                              <input
                                type="file"
                                className="form-control"
                                onChange={uploadMultipleFiles}
                                multiple
                              />
                            </div>
                            <button
                              type="button"
                              className="btn btn-danger btn-block"
                              onClick={uploadFiles}
                            >
                              Upload
                            </button>
                          </form>
                        </div>

                        <div className="row">
                          {(images.media || []).map((url, i) => (
                            <div className="p-2" key={i}>
                              <input
                                name="images"
                                type="checkbox"
                                value={url.media_url}
                                checked={imagesUrl.includes(url.media_url)}
                                onChange={handleSelect}
                              />
                              <img
                                src={url.media_url}
                                width="100px"
                                height="100px"
                                alt="..."
                                className="variation-images"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={toggle}>
                        ok
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>

                <button
                  type="submit"
                  className="btn btn-block publish-btn  text-center pt-2"
                  disabled={check}
                >
                  {check ? "publishing" : "publish auction"}
                </button>
              </form>
            </CardAdmin>
          </div>

          <div className="mb-3">
            <Modal isOpen={modalCategory} toggle={toggleCategory}>
              <ModalHeader toggle={toggleCategory}>Add category</ModalHeader>
              <ModalBody>
                <form className="form">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="enter category"
                      onChange={(e) => setCat({ ...cat, name: e.target.value })}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="enter sub categories  e.g  Shirts, Pants, Underwears"
                      onChange={(e) => setCat({ ...cat, subs: e.target.value })}
                    />
                  </div>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={addCat}>
                  add
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <div className="mb-3">
            <Modal isOpen={modalSubCat} toggle={toggleSubCat}>
              <ModalHeader toggle={toggleSubCat}>Add Sub Category</ModalHeader>
              <ModalBody>
                <form className="form">
                  <div className="input-group mb-3 col-md-12">
                    <select
                      className="custom-select mb-2"
                      id="inputGroupSelect01"
                      // value={}
                      onChange={(e) =>
                        setCat({ ...cat, category_id: e.target.value })
                      }
                    >
                      <option selected>Select Categories...</option>
                      {category.map((index, i) => {
                        return (
                          <option key={i} value={index.id}>
                            {index.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="enter sub categories  e.g  Shirts, Pants, Underwears"
                      onChange={(e) => setCat({ ...cat, subs: e.target.value })}
                    />
                  </div>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={addSubCat}>
                  add
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </section>
    </Layout>
  );
};

AddAuction.propTypes = {
  category: PropTypes.object.isRequired,
  fetchCategory: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  category: state.category,
  media: state.media,
  auction: state.auction,
});

const pageActions = {
  fetchCategory,
  fetchAllMedia,
  fetchPostAuction,
  fetchUploadMedia,
  fetchAddCategory,
};

export default connect(mapStateToProps, pageActions)(AddAuction);
