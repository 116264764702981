import {
  FETCH_INVENTORIES,
  FETCH_INVENTORIES_SUCCESS,
  FETCH_INVENTORIES_FAILED
} from '../../types';

const initialState = {
  inventories: {},
  isLoading: false,
  errors: {},
  error: false
}

const fetchInventoriesReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_INVENTORIES:
      return {
        ...state,
        isLoading: true,
          inventories: action.payload
      }
      case FETCH_INVENTORIES_SUCCESS:
        return {
          ...state,
          isLoading: false,
            inventories: action.payload
        }
        case FETCH_INVENTORIES_FAILED:
          return {
            ...state,
            isLoading: false,
              errors: action.payload,
              error: true
          }
          default:
            return {
              ...state
            };
  }
}

export default fetchInventoriesReducer;
