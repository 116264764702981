import {
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAILED,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAILED,
  FETCH_DISPUTE_SUCCESS,
  FETCH_DISPUTE_FAILED,
  FETCH_DISPUTES_SUCCESS,
  FETCH_DISPUTES_FAILED
} from '../types';
import axios from 'axios'

import {
  BASE_URL
} from '../constant';

import Auth from '../../config/auth'

export const fetchOrders = (filter) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/orders/admin/view/1585139305?filter=${filter}&pages=1&paginate=30`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_ORDERS_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_ORDERS_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_ORDERS_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchProcessOrders = (id, data) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.post(`${BASE_URL}/orders/process/${id}`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_ORDER_SUCCESS,
          payload: response.data
        })
        dispatch(fetchOrders())
      } else {
        dispatch({
          type: FETCH_ORDER_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_ORDER_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchCancelOrders = (id) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.put(`${BASE_URL}/orders/cancel/${id}`, {}, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_ORDER_SUCCESS,
          payload: response.data
        })
        dispatch(fetchOrders())
      } else {
        dispatch({
          type: FETCH_ORDER_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_ORDER_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchDispute = (id) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.put(`${BASE_URL}/orders/cancel/${id}`, {}, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_ORDER_SUCCESS,
          payload: response.data
        })
        dispatch(fetchOrders())
      } else {
        dispatch({
          type: FETCH_ORDER_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_ORDER_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchDisputes = (id) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/orders/dispute/${id}`, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_DISPUTES_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_DISPUTES_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_DISPUTES_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}
