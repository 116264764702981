import React, { useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import photo from "../../images/photo.png";
import "./styles.scss";
import CardAdmin from "../../components/Card/CardAdmin";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";

import {
  fetchImportProducts,
  fetchActionImportProducts,
} from "../../redux/actions/product";
import { fetchCategory } from "../../redux/actions/category";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalCard from "../../components/ModalCard/ModalCard";
import swal from "sweetalert";

import numberToComma from "../../utils/numberToComma";
import { useState } from "react";

import Axios from "axios";
import { BASE_URL } from "../../redux/constant";
import Auth from "../../config/auth";

const ImportProduct = (props) => {
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [check, setCheck] = useState(false);
  const [checkSuccess, setCheckSuccess] = useState(false);
  const [data, setData] = useState({});
  const [form, setForm] = useState({});
  const [modal, setModal] = useState(false);
  const [store, setStore] = useState({});
  const [catKey, setCatKey] = useState(0);

  const varia = store?.product?.product_variations.find(id => id.id === data?.variation_id)

  const toggleApprove = () => setModal(!modal);

  function handleCategory(e) {
    setForm({ ...form, categoty_id: parseInt(category[e.target.value].id) });
    setCatKey(e.target.value);
    console.log(form)
  }

  function handleSubCat(e) {
    setForm({ ...form, sub_category: e.target.value });
    console.log(form)
  }

  function declineExport(id) {
    setCheck(true);
    const data = { moved_product_id: id, status: "declined" };
    props.fetchActionImportProducts(data);
  }

  function approve() {
    setCheckSuccess(true);
    const postdata = {
      ...form,
      moved_product_id: data.id,
      status: "approved",
      quantity: data.quantity
    };
    console.log(form);
    props.fetchActionImportProducts(postdata);
  }

  function approveView(data) {
    setData(data);
    getProduct(data.product.id)
    setForm({
      ...form,
      price: data?.product?.price,
      phone: data?.product?.phone,
      discount: data?.product?.discount,
      discount_type: data?.product?.discount_type,
      quantity: data?.quantity
    })
    toggleApprove();
  }

  function decline(id) {
    swal({
      title: "Are you sure?",
      text: "Once declined, you will not be able to recover this product!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        declineExport(id);
        // swal("Poof! Your imaginary file has been deleted!", {
        //   icon: "success",
        // });
      } else {
        swal("Your imaginary file is safe!");
      }
    });
  }

  function filter(e) {
    props.fetchImportProducts(e.target.value);
  }

  async function getProduct(id){
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.get(`${BASE_URL}/products/viewone/${id}`,{headers});
      if(response.data.hasError){
        console.log(response.data)
      } else {
        setStore(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    props.fetchImportProducts();
    props.fetchCategory();
  }, []);

  useEffect(() => {
    if (props.import_products.import_products.data) {
      setProducts(props.import_products.import_products.data.moveProduct);
    }
    if (check) {
      swal("product was decline", "", "success");
      setCheck(false);
    }
    if (checkSuccess) {
      swal("product was approved", "", "success");
      toggleApprove();
      setForm({ price: "", phone: "", discount: "", discount_type: "" });
      setCheckSuccess(false);
    }
  }, [props.import_products.import_products]);

  useEffect(() => {
    if (props.category.category.data) {
      setCategory(props.category.category.data.store.categories);
      // if (check) {
      //   swal("cattegory added", "", "success");
      // }
    }
  }, [props.category.category.data]);

  useEffect(() => {
    if (props.import_products.errors.errors && check) {
      swal(props.import_products.errors.errors.message, "", "error");
      setCheck(false);
    }
    if (props.import_products.errors.errors && checkSuccess) {
      swal(props.import_products.errors.errors.message, "", "error");
      setCheckSuccess(false);
    }
  }, [props.import_products.errors.errors]);

  return (
    <Layout>
      <section className="dash-section ">
        <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <h3>Imported products</h3>

        <div className="row dash-card-info-3">
          <div className="col-md-12 ">
            <label htmlFor="">Filter orders</label>
            <div className="input-group mb-3 col-md-3">
              <select
                className="custom-select"
                id="inputGroupSelect01"
                onChange={filter}
              >
                <option selected>all</option>
                <option>processing</option>
                <option>approved</option>
                <option>declined</option>
              </select>
            </div>

            <table class="table table-borderless table-hover table-striped table-responsive-sm">
              <thead>
                <tr>
                  <th>Market place</th>
                  <th></th>
                  <th className="prod-admin-subtitle ">
                    for September 2020
                    <span>
                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {(products || []).map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <img
                          className="admin-profile-container"
                          src={item.product.images[0]}
                          alt="admin"
                          width="30%"
                        />
                      </td>
                      <td>
                        <div>
                          <p className="dash-info-title pb-3">
                            {item.product.product_name}
                          </p>
                          <p className="dash-info-subtitle">
                            total: {item.quantity}
                          </p>
                          {/* <p className="dash-info-subtitle">
                              variation: {item.product_variation?.variation}
                            </p> */}
                        </div>
                      </td>
                      <td>
                        <div>
                          <p className="dash-info-subtitle pb-3">view</p>
                          <p className="dash-info-title">
                            ₦ {numberToComma(item.product.net_price)}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <p
                            className={
                              item.approved === "processing"
                                ? `badge badge-warning`
                                : item.approved === "approved"
                                ? `badge badge-success`
                                : item.approved === "declined"
                                ? `badge badge-danger`
                                : ""
                            }
                          >
                            {item.approved}
                          </p>
                        </div>
                      </td>
                      <td>
                        <button
                          className="m-1 btn btn-sm btn-primary"
                          onClick={() => approveView(item)}
                          disabled={
                            item.approved === "approved" ||
                            item.approved === "declined"
                              ? true
                              : false
                          }
                        >
                          approve
                        </button>
                        <button
                          className="m-1 btn btn-sm btn-danger"
                          onClick={() => decline(item.id)}
                          disabled={
                            item.approved === "approved" ||
                            item.approved === "declined"
                              ? true
                              : false
                          }
                        >
                          decline
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <ModalCard
              title="Approve item"
              toggle={toggleApprove}
              modal={modal}
            >
              <img
                className=""
                src={data?.product?.images[0]}
                alt="admin"
                width="30%"
              />
              <h4>{data?.product?.product_name}</h4>
              <h6>From: {store?.store?.store_name}</h6>
              <h6>To: Shobbu</h6>  
              {data?.variation_id !== null && (
                <table className="table">
                  <th>name</th>
                  <th>price</th>
                  <th>quantity</th>
                  <th>discount</th>
                 <tr>
              <td>{varia?.variation}</td>
              <td>{varia?.price}</td>
              <td>{varia?.quantity}</td>
              <td>{varia?.discount}</td>
                 </tr>
                </table>
              )}
              <br/>
              <form className="form">
                <label htmlFor="price">Price</label>
                <div className="form-group mb-3">
                  <input
                    type="number"
                    name="price"
                    className="form-control"
                    required
                    value={form.price}
                    placeholder="enter price"
                    onChange={(e) =>
                      setForm({ ...form, price: e.target.value })
                    }
                  />
                </div>
                <label htmlFor="price">Quantity</label>
                <div className="form-group mb-3">
                  <input
                    type="number"
                    name="price"
                    className="form-control"
                    required
                    value={form.quantity}
                    placeholder="enter qunatity"
                    onChange={(e) =>
                      setForm({ ...form, quantity: e.target.value })
                    }
                  />
                </div>
                <div className=" mb-3">
                  <label htmlFor="discount">Discount</label>
                  <input
                    type="number"
                    name="discount"
                    placeholder="discount %"
                    className="form-control"
                    required
                    value={form.discount}
                    onChange={(e) =>
                      setForm({ ...form, discount: e.target.value })
                    }
                  />
                </div>
                <label htmlFor="discount_type">Discount Type</label>
                <div className="input-group mb-3">
                  
                  <select
                    className="custom-select"
                    id="inputGroupSelect02"
                    value={form.discount_type}
                    onChange={(e) =>
                      setForm({ ...form, discount_type: e.target.value })
                    }
                  >
                    <option selected>Select discount type</option>
                    <option>value</option>
                    <option>percent</option>
                  </select>
                </div>
         
                <label htmlFor="">Category</label>
                <div className="row">
                  <div className="input-group mb-3 col-md-12">
                    <select
                      className="custom-select"
                      id="inputGroupSelect01"
                      //value={catKey}
                      onChange={handleCategory}
                    >
                      <option selected>Select Categories...</option>
                      {category.map((index, i) => {
                        return (
                          <option key={i} value={i}>
                            {index.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                
                </div>

                <label htmlFor="">Sub Category</label>
                <div className="row">
                  <div className="input-group mb-3 col-md-12">
                    <select
                      className="custom-select"
                      id="inputGroupSelect01"
                      onChange={handleSubCat}
                    >
                      <option selected>Select Sub Categories...</option>
                      {(
                        (category.length > 1 &&
                          category[catKey].subCategories) ||
                        []
                      ).map((name, i) => {
                        return (
                          <option key={i} value={name}>
                            {name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                
                </div>



              </form>
              <button className="btn btn-primary" onClick={approve}>
                Approve
              </button>
            </ModalCard>
          </div>
        </div>
      </section>
    </Layout>
  );
};

ImportProduct.propTypes = {
  import_products: PropTypes.object.isRequired,
  fetchImportProducts: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  import_products: state.import_products,
  category: state.category,
});

const pageActions = {
  fetchImportProducts,
  fetchActionImportProducts,
  fetchCategory,
};

export default connect(mapStateToProps, pageActions)(ImportProduct);
