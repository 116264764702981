import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import photo from "../../images/photo.png";
import "./styles.scss";
import CardAdmin from "../../components/Card/CardAdmin";
import MyChart from "../../components/ChartAdmin/ChartAdmin";
import Chart from 'react-apexcharts'
import { useLocation, useHistory } from "react-router-dom";
import Axios from "axios";
import { BASE_URL } from "../../redux/constant";
import Auth from "../../config/auth";
import numberWithCommas from "../../utils/numberToComma";
import dayjs from "dayjs";

const Dashboard = (props) => {
  const history = useLocation();
  const [metrics, setMetrics] = useState({});
  const [series,setSeries] = useState([{
    name: 'series-1',
    data: []
  }]);


  const [options, setOptions] = useState({
    chart: {
      id: 'apexchart-example'
    },
    xaxis: {
      categories: []
    }
  });

async function dataChart() {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await Axios.get(
      `${BASE_URL}/merchants/graphs/weekly/1585139305`,
      { headers }
    );
    if (response.data.hasError) {
      console.log( response.data.data.weekly_revenue);
    } else {
      console.log( response.data.data.weekly_revenue.x);
      if(response.data.data.weekly_revenue){
        setSeries([{...series,  data: response.data.data.weekly_revenue.y} ]); 
        setOptions({...options, xaxis:{categories: response.data.data.weekly_revenue?.x} }); 
      }
    
      console.log(response.data); 
    }
  } catch (error) {
    console.log(error);
  }
}

  async function fetch() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.get(
        `${BASE_URL}/merchants/meta/1585139305`,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        setMetrics(response.data.data.store_data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchStore() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.get(
        `${BASE_URL}/merchants/store/view/1585139305`,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        console.log(response.data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetch();
    fetchStore()
    dataChart()
  }, []);

  return (
    <Layout>
      {console.log(metrics)}
      <section className="dash-section ">
        <div className="dash-info-card-chart">
          <CardAdmin>
            {/* <MyChart /> */}
            <Chart options={options} series={series} type="bar" width="100%" height={400} />
          </CardAdmin>
        </div>

        <div className="row dash-info-card">
          <div className="col-md-3">
            <CardAdmin>
              <div className="d-flex justify-content-between mb-3">
                <p className="dash-info-title">Total Visitors</p>
                <i
                  class="fa fa-line-chart fa-lg"
                  style={{ color: "orange" }}
                  aria-hidden="true"
                ></i>
              </div>
              <div className="d-flex justify-content-between">
                <p className="dash-info-digit">{metrics.site_visits}</p>
                <i class="fa fa-caret-up" aria-hidden="true"></i>
              </div>
            </CardAdmin>
          </div>
          <div className="col-md-3">
            <CardAdmin>
              <div className="d-flex justify-content-between mb-3">
                <p className="dash-info-title">Total Orders</p>
                <i
                  class="fa fa-line-chart fa-lg"
                  style={{ color: "red" }}
                  aria-hidden="true"
                ></i>
              </div>
              <div className="d-flex justify-content-between">
                <p className="dash-info-digit">{metrics.new_orders}</p>
                <i class="fa fa-caret-up" aria-hidden="true"></i>
              </div>
            </CardAdmin>
          </div>
          <div className="col-md-3">
            <CardAdmin>
              <div className="d-flex justify-content-between mb-3">
                <p className="dash-info-title">Total Auction</p>
                <i
                  class="fa fa-line-chart fa-lg"
                  style={{ color: "green" }}
                  aria-hidden="true"
                ></i>
              </div>
              <div className="d-flex justify-content-between">
                <p className="dash-info-digit">{metrics.all_auction}</p>
                <i class="fa fa-caret-up" aria-hidden="true"></i>
              </div>
            </CardAdmin>
          </div>
          <div className="col-md-3">
            <CardAdmin>
              <div className="d-flex justify-content-between mb-3">
                <p className="dash-info-title">
                  Total Revenue ( <span>NGN</span> )
                </p>
                <i
                  class="fa fa-line-chart fa-lg"
                  style={{ color: "lightblue" }}
                  aria-hidden="true"
                ></i>
              </div>
              <div className="d-flex justify-content-between">
                <p className="dash-info-digit">
                  {numberWithCommas(
                    metrics?.total_revenue
                      ? metrics.total_revenue.toFixed(2)
                      : ""
                  )}
                </p>
                <i class="fa fa-caret-up" aria-hidden="true"></i>
              </div>
            </CardAdmin>
          </div>
        </div>

        <div className="row dash-info-card-2">
          <div className="col-md-4">
            <CardAdmin>
              <div className="d-flex justify-content-between mb-3">
                <div>
                  <p className="dash-info-title">Customers</p>
                  <p className="dash-info-subtitle">
                    Total number of registered customers
                  </p>
                </div>
                <i class="fa fa-cog" aria-hidden="true"></i>
              </div>
              <p className="dash-info-digit">{metrics.no_of_customers}</p>
            </CardAdmin>
          </div>
          <div className="col-md-4">
            <CardAdmin>
              <div className="d-flex justify-content-between mb-3">
                <div>
                  <p className="dash-info-title">Merchants</p>
                  <p className="dash-info-subtitle">
                    Total number of registered merchants
                  </p>
                </div>
                <i class="fa fa-cog" aria-hidden="true"></i>
              </div>
              <p className="dash-info-digit">5845</p>
            </CardAdmin>
          </div>
          <div className="col-md-4">
            <CardAdmin>
              <div className="d-flex justify-content-between mb-3">
                <div>
                  <p className="dash-info-title">Subscribers</p>
                  <p className="dash-info-subtitle">Active subscribers</p>
                </div>
                <i class="fa fa-cog" aria-hidden="true"></i>
              </div>
              <p className="dash-info-digit">3845</p>
            </CardAdmin>
          </div>
        </div>

        <div className="row dash-card-info-3">
          <div className="col-md-9 ">
            <h6>Latest sales</h6>
            <div className="table-responsive ">
              <table class="table table-borderless table-striped table-hover">
                <thead>
                  <tr>
                    <th>Store id</th>
                    <th>Quantity</th>
                    <th>Week Revenue (₦)</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {" "}
                  {(metrics.revenue || []).map((revenue, i) => {
                    return (
                      <tr>
                        <td>{revenue.store_id}</td>
                        <td>{revenue.total_quantity}</td>
                        <td>
                          {numberWithCommas(revenue.total_revenue_this_week)}
                        </td>
                        <td>
                          {dayjs(revenue.updatedAt).format("YYYY-MM-DD")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* <CardAdmin>
              <table class="table table-borderless table-hover  table-responsive-sm">
                <thead>
                  <tr>
                    <th>Latest sales</th>
                    <th></th>
                    <th></th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img
                        className="admin-profile-container"
                        src={photo}
                        alt="admin"
                        width="30%"
                      />
                    </td>
                    <td>
                      <div>
                        <p className="dash-info-title">Classic Ladies wear</p>
                        <p className="dash-info-subtitle">
                          Customer: Ayodeji Osindele{" "}
                        </p>
                        <p className="dash-info-subtitle">
                          Merchant: XYZ Limited
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p className="dash-info-subtitle pb-3">
                          17 Oct - 1 hour ago
                        </p>
                        <p className="dash-info-title">N 5,000</p>
                      </div>
                    </td>
                    <td>
                      <i
                        class="fa fa-check fa-2x"
                        aria-hidden="true"
                        style={{ color: "lightgreen" }}
                      ></i>
                      <p className="dash-info-subtitle">success</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        className="admin-profile-container"
                        src={photo}
                        alt="admin"
                        width="30%"
                      />
                    </td>
                    <td>
                      <div>
                        <p className="dash-info-title">iPhone X Max</p>
                        <p className="dash-info-subtitle">
                          Customer: Ayodeji Osindele{" "}
                        </p>
                        <p className="dash-info-subtitle">
                          Merchant: XYZ Limited
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p className="dash-info-subtitle pb-3">
                          17 Oct - 1 hour ago
                        </p>
                        <p className="dash-info-title">N 5,000</p>
                      </div>
                    </td>
                    <td>
                      <i
                        class="fa fa-minus fa-2x"
                        style={{ color: "red" }}
                        aria-hidden="true"
                      ></i>
                      <p className="dash-info-subtitle">pending</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardAdmin> */}
          </div>
          <div className="col-md-3">
          <CardAdmin>
              <div className="text-center mb-2">
                <i
                  class="fa fa-check fa-2x"
                  style={{ color: "lightgreen" }}
                  aria-hidden="true"
                ></i>
                <p className="dash-info-title">products</p>
                <p className="dash-info-subtitle">
                  Number of products.
                </p>
                <p className="dash-info-digit">{metrics.no_of_products}</p>
                {/* <p className="dash-info-btn">details</p> */}
              </div>
            </CardAdmin>
            <CardAdmin>
              <div className="text-center mb-2">
                <i
                  class="fa fa-check fa-2x"
                  style={{ color: "lightgreen" }}
                  aria-hidden="true"
                ></i>
                <p className="dash-info-title">Successfull sales</p>
                <p className="dash-info-subtitle">
                  Number of successful sales has been fulfilled.
                </p>
                <p className="dash-info-digit">{metrics.no_of_products_sold}</p>
                <p className="dash-info-btn">details</p>
              </div>
            </CardAdmin>
            <CardAdmin>
              <div className="text-center">
                <i
                  class="fa fa-minus fa-2x"
                  style={{ color: "red" }}
                  aria-hidden="true"
                ></i>
                <p className="dash-info-title">Pending sales</p>
                <p className="dash-info-subtitle">
                  Number of successful sales has been fulfilled.
                </p>
                <p className="dash-info-digit">1225</p>
                <p className="dash-info-btn">details</p>
              </div>
            </CardAdmin>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Dashboard;
