import React from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";

import ErrorPage from "./pages/ErrorPage";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Dashboard from "./pages/Dashboard/Dashboard";
import Product from "./pages/Product/Product";
import Subscription from "./pages/Subscription/Subscription";
import Profile from "./pages/Profile/Profile";

import Auth from "./config/auth";
import Login from "./pages/Login/Login";
import Rating from "./pages/Rating/Rating";
import AddProduct from "./pages/AddProduct/AddProduct";
import SingleProfile from "./pages/CustomerProfile/CustomerProfile";
import Media from "./pages/Media/Media";
import Coupon from "./pages/Coupon/Coupon";
import Auction from "./pages/Auction/Auction";
import Orders from "./pages/Orders/Orders";
import AddAuction from "./pages/AddAuction/AddAuction";
import EditProduct from "./pages/EditProduct/EditProduct";
import Inventory from "./pages/Inventory/Inventory";
import CustomerProfile from "./pages/CustomerProfile/CustomerProfile";
import StoreProfile from "./pages/StoreProfile/StoreProfile";
import ImportProduct from "./pages/ImportProduct/ImportProduct";
import Withdrawal from "./pages/Withdrawal/Withdrawal";
import Settings from "./pages/Settings/Settings";
import Transaction from "./pages/Transaction/Transaction";
import Tax from "./pages/Tax/Tax";
import Staff from "./pages/Staff/Staff";
import Notification from "./pages/Notification/Notification";
import SoldOut from "./pages/SoldOut/SoldOut";

const App = (props) => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Login} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/product" component={Product} />
        <PrivateRoute exact path="/auction" component={Auction} />
        <PrivateRoute exact path="/orders" component={Orders} />
        <PrivateRoute exact path="/subscription" component={Subscription} />
        <PrivateRoute exact path="/inventory" component={Inventory} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/rating" component={Rating} />
        <PrivateRoute exact path="/add-product" component={AddProduct} />
        <PrivateRoute
          exact
          path="/edit-product/:product"
          component={EditProduct}
        />

        <PrivateRoute exact path="/add-auction" component={AddAuction} />
        <PrivateRoute exact path="/customer-profile/:user" component={CustomerProfile} />
        <PrivateRoute exact path="/store-profile/:user" component={StoreProfile} />
        <PrivateRoute exact path="/media" component={Media} />
        <PrivateRoute exact path="/coupon" component={Coupon} />
        <PrivateRoute exact path="/import-product" component={ImportProduct} />
        <PrivateRoute exact path="/withdrawal" component={Withdrawal} />
        <PrivateRoute exact path="/settings" component={Settings} />
        <PrivateRoute exact path="/transactions" component={Transaction} />
        <PrivateRoute exact path="/tax" component={Tax} />
        <PrivateRoute exact path="/staff" component={Staff} />
        <PrivateRoute exact path="/notification" component={Notification} />
        <PrivateRoute exact path="/sold-out" component={SoldOut} />
        <Route exact path="*" component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Auth.isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      )
    }
  />
);

export default App;
