import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import photo from "../../images/photo.png";
import "./styles.scss";
import CardAdmin from "../../components/Card/CardAdmin";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import numberToComma from "../../utils/numberToComma";

import Axios from "axios";
import { BASE_URL } from "../../redux/constant";
import Auth from "../../config/auth";

import {
  fetchWithdrawal,fetchPutWithdrawal
} from "../../redux/actions/product";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useEffect } from "react";
import swal from "sweetalert";
import dayjs from "dayjs";

const Withdrawal = (props) => {
  const [withdrawals, setWithdrawal] = useState([]);
  const [modal, setModal] = useState(false);
  const [request, setRequest] = useState({})
  const [form, setForm] = useState({});
  const [check, setCheck] = useState(false);
  const [checkErr, setCheckErr] = useState(false);

  const toggle = () => setModal(!modal);

  function viewRequest(data){
      setForm({...form,request_id: data.id})
      setRequest(data?.store)
      toggle()
  }

  function sendRequest(){
    setCheck(true)
    const data = { request_id:form.request_id, approved: true}
    props.fetchPutWithdrawal(data)
  }

  function cancelRequest(){
    setCheckErr(true)
    const data = { request_id:form.request_id, approved: false}
    props.fetchPutWithdrawal(data)
  }

  useEffect(() => {
    props.fetchWithdrawal();
  }, []);

  useEffect(() => {
    if (props.withdrawal.withdrawal.data) {
      setWithdrawal(props.withdrawal.withdrawal.data.requests);
    }
    if (check) {
      swal("Approved successfully", "", "success");
      setForm({});
      setCheck(false);
      toggle()
    }
    if (checkErr) {
      swal("Declined successfully", "", "success");
      setForm({});
      setCheckErr(false);
      toggle()
    }
  }, [props.withdrawal.withdrawal]);

  useEffect(() => {
    if (check && props.withdrawal.errors.errors) {
      swal(props.withdrawal.errors.errors.message, "", "error");
      setForm({});
      setCheckErr(false);
    }
    if (check || checkErr) {
      swal("An error occurred", props.withdrawal.errors.errors.message, "error");
      setCheck(false);
      setCheckErr(false)
    }
  }, [props.withdrawal.errors.errors]);

  return (
    <Layout>
      <section className="dash-section ">
        <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <h3 className="pt-3 pb-3">Withdrawal request(s)</h3>
        
          <div className="table-responsive ">
            <table className="table table-borderless table-striped table-hover ">
              <thead>
                <tr>
                  <th>Store id</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {(withdrawals || []).map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.store_id}</td>
                      <td>{item.description}</td>
                      <td>{numberToComma(item.amount)}</td>
                      <td>
                  <span className={item.status===`pending`?`badge badge-warning`:`badge badge-success`}>{item.status}</span>
                      </td>
                      <td>{dayjs(item.createdAt).format('YYYY MM DD')}</td>
                   
                      <td>
                        <button
                          onClick={() => viewRequest(item)}
                          className="btn btn-sm btn-outline-primary"
                        >
                          view
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>


        <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>{request?.store_name}</ModalHeader>
          <ModalBody>
                <img src={request.logo} alt="store" width="30%"/>
              <h6>Phone: {request.phone}</h6>
              <h6>Email: {request.email}</h6>
              <h6>Location: {request.store_location_address}</h6>
              <h6>Bank: {request.BankAccountName}</h6>
              <h6>Account: {request.BankAccountNumber}</h6>
                <br/>
              
          </ModalBody>
          <ModalFooter>
          <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          <Button color="danger" onClick={cancelRequest}>
              Disapprove
            </Button>
            <Button color="primary" onClick={sendRequest}>
              Approve
            </Button>
          </ModalFooter>
        </Modal>
      </section>
    </Layout>
  );
};

Withdrawal.propTypes = {
  withdrawal: PropTypes.object.isRequired,
  fetchWithdrawal: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  withdrawal: state.withdrawal,
});

const pageActions = {
  fetchWithdrawal,fetchPutWithdrawal
};

export default connect(mapStateToProps, pageActions)(Withdrawal);
