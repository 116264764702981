import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import photo from "../../images/photo.png";
import "./styles.scss";
import CardAdmin from "../../components/Card/CardAdmin";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import numberToComma from "../../utils/numberToComma";

import {
  fetchAuctions,
  fetchAuction,
  fetchDeclareWinner,
} from "../../redux/actions/auctions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useEffect } from "react";
import ModalCard from "../../components/ModalCard/ModalCard";
import Axios from "axios";
import { BASE_URL } from "../../redux/constant";
import Auth from "../../config/auth";
import swal from "sweetalert";

const Auction = (props) => {
  const history = useHistory();
  const [auctions, setAuctions] = useState([]);
  const [auction, setAuction] = useState({});
  const [bids, setBids] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  function viewAuction(data) {
    setAuction(data);
    props.fetchAuction(data.id);
    toggle();
  }

  function declareWinner(id) {
    props.fetchDeclareWinner(id);
    toggle();
  }

  function deleteAuctionView(id) {
    swal({
      title: "Are you sure?",
      text:
        "Once deleted, you will not be able to recover this variation file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteAuction(id);
      } else {
        swal("Your imaginary file is safe!");
      }
    });
  }

  async function deleteAuction(id) {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.delete(
        `${BASE_URL}/merchants/delete-auction/${id}`,
        { headers }
      );
      if (response.data.hasError) {
        swal(response.data.message, "", "error");
      } else {
        swal(response.data.message, "", "success");
        props.fetchAuctions();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function sendReminder(id) {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.get(
        `${BASE_URL}/auctions/1585139305/${id}`,
        { headers }
      );
      if (response.data.hasError) {
        swal(response.data.message, "", "error");
      } else {
        swal(response.data.message, "", "success");
        props.fetchAuctions();
        toggle()
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    props.fetchAuctions();
  }, []);

  useEffect(() => {
    if (props.auctions.auctions.data) {
      setAuctions(props.auctions.auctions.data.auction);
    }
  }, [props.auctions.auctions]);

  useEffect(() => {
    if (props.auction.auction.data) {
      setBids(props.auction.auction.data.bids.bids);
    }
  }, [props.auction.auction.data]);

  return (
    <Layout>
      <section className="dash-section ">
        <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <h3>Auction</h3>
        <div className="row dash-card-info-3">
          <div className="col-md-12 ">
            <Link to="/add-auction">
              <button className="btn btn-block product-btn mb-3">
                <p> + </p>
                <p>Create Auction</p>
              </button>
            </Link>

            <table class="table table-borderless table-hover table-striped table-responsive-sm">
              <thead>
                <tr>
                  <th>Auction place</th>
                  <th></th>
                  <th className="prod-admin-subtitle ">
                    for September 2020
                    <span>
                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {(auctions || []).map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <img
                          className="admin-profile-container"
                          src={item.product.images[0]}
                          alt="admin"
                          width="30%"
                        />
                      </td>
                      <td>
                        <div>
                          <p className="dash-info-title pb-3">
                            {item.product.product_name}
                          </p>
                          <p className="dash-info-subtitle">
                            {item.start_date} - {item.end_date}
                          </p>
                          <p className="dash-info-subtitle">
                            Merchant: Shobbu Limited
                          </p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <p className="dash-info-subtitle pb-3">view</p>
                          <p className="dash-info-title">
                            ₦ {numberToComma(item.current_price)}
                          </p>
                        </div>
                      </td>
                      <td>
                        <button
                          className="m-2 btn btn-sm btn-info"
                          onClick={() => viewAuction(item)}
                        >
                          <i className="fa fa-eye" aria-hidden="true"></i> view
                        </button>
                        <button
                          className="m-2 btn btn-sm btn-danger"
                          onClick={() => deleteAuctionView(item.id)}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>{" "}
                          remove
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <ModalCard
              title={auction.product?.product_name ?? ""}
              toggle={toggle}
              modal={modal}
            >
              <div>
                <div className="d-flex justify-content-around">
                  <img
                    src={auction.product?.images[0] ?? ""}
                    alt="auction"
                    width="40%"
                  />
                  <div>
                    <p>
                      Current price :
                      <strong>
                        ₦ {numberToComma(auction.current_price ?? "")}
                      </strong>
                    </p>
                    <p>
                      Starting price :
                      <strong>
                        ₦ {numberToComma(auction.starting_price ?? "")}
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between pt-2">
                  <p>
                    start date :<strong>{auction.start_date ?? ""}</strong>
                  </p>
                  <p>
                    end date : <strong>{auction.end_date ?? ""}</strong>
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p>
                    start time :<strong>{auction.start_time ?? ""}</strong>
                  </p>
                  <p>
                    end time : <strong>{auction.end_time ?? ""}</strong>
                  </p>
                </div>
              </div>
              <div className="place-bid-item">
                <div className="place-bid-item-body p-4">
                  <h5 className="p-2">Highest bidders</h5>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Bid no.</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(bids || []).map((item, i) => {
                        return (
                          <tr key={i}>
                            <th>#{item.id}</th>
                            <td>{numberToComma(item.bid)}</td>
                            <td>{item.winner ? "Winning" : "Loosing"}</td>
                            <td>
                              <input
                                class="form-check-input"
                                type="radio"
                                onChange={() => declareWinner(item.id)}
                                checked={item.winner}
                              />
                            </td>
                        <td>{item.winner && <button className="btn btn-sm btn-info" onClick={() => sendReminder(item.auction_id)}>send reminder</button>}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </ModalCard>
          </div>
        </div>
      </section>
    </Layout>
  );
};

Auction.propTypes = {
  auctions: PropTypes.object.isRequired,
  fetchAuctions: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auctions: state.auctions,
  auction: state.auction,
});

const pageActions = {
  fetchAuctions,
  fetchAuction,
  fetchDeclareWinner,
};

export default connect(mapStateToProps, pageActions)(Auction);
