import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import "./styles.scss";
import { Link } from "react-router-dom";
import numberToComma from "../../utils/numberToComma";

import Axios from "axios";
import { BASE_URL } from "../../redux/constant";
import Auth from "../../config/auth";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useEffect } from "react";
import swal from "sweetalert";
import dayjs from "dayjs";

const Transaction = (props) => {
  const [transactions, setTransaction] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.get(
        `${BASE_URL}/transaction/history/1585139305`,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        const {store_transactions} = response.data.data;
        setTransaction(store_transactions);
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
      swal("network error","","error");
    }
  }

  return (
    <Layout>
      <section className="dash-section ">
        {/* <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div> */}
        <h3 className="pt-3 pb-3">Transaction history</h3>

        <div className="table-responsive ">
          <table className="table table-borderless table-striped table-hover ">
            <thead>
              <tr>
                <th>Store id</th>
                <th>Description</th>
                <th>Amount</th>
                <th>previous bal</th>
                <th>current bal</th>
                <th>Type</th>
                <th>Platform</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {(transactions || []).map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.store_id}</td>
                    <td>{item.transaction_reason}</td>
                    <td>{item.amount}</td>
                    <td>{item.previous_balance}</td>
                    <td>{item.current_balance}</td>
                    <td>{item.transaction_type}</td>
                    <td>{item.payment_media.media}</td>
                    <td>{dayjs(item.createdAt).format("YYYY MM DD")}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    </Layout>
  );
};

Transaction.propTypes = {};
const mapStateToProps = (state) => ({});

const pageActions = {};

export default connect(mapStateToProps, pageActions)(Transaction);
