import {
  FETCH_COUPON_SUCCESS,
  FETCH_COUPON_FAILED,
  FETCH_COUPONS_SUCCESS,
  FETCH_COUPONS_FAILED
} from '../types';
import axios from 'axios'

import {
  BASE_URL
} from '../constant';

import Auth from '../../config/auth'


export const fetchCoupon = (data) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.post(`${BASE_URL}/merchants/coupon`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_COUPON_SUCCESS,
          payload: response.data
        })
        dispatch(fetchCoupons())
      } else {
        dispatch({
          type: FETCH_COUPON_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_COUPON_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchCoupons = () => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/merchants/coupon/1585139305?pages=1&paginate=20`, token != null && ({
      headers
    }))
    .then(response => {
      console.log(response)
      if (!response.hasError) {
        dispatch({
          type: FETCH_COUPONS_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_COUPONS_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_COUPONS_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchDeleteCoupon = (id) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.delete(`${BASE_URL}/merchants/coupon/${id}`, token != null && ({
      headers
    }))
    .then(response => {
      console.log(response)
      if (!response.hasError) {
        dispatch({
          type: FETCH_COUPON_SUCCESS,
          payload: response.data
        })
        dispatch(fetchCoupons())
      } else {
        dispatch({
          type: FETCH_COUPON_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_COUPON_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}
