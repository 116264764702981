import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import photo from "../../images/photo.png";
import "./styles.scss";
import CardAdmin from "../../components/Card/CardAdmin";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import truncate from "../../utils/truncate";

import Axios from "axios";
import { BASE_URL } from "../../redux/constant";
import Auth from "../../config/auth";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useEffect } from "react";
import swal from "sweetalert";
import dayjs from "dayjs";

const Settings = (props) => {
  const [form, setForm] = useState({store_id:"1585139305"});
  const [store, setStore] = useState({})
  const [check, setCheck] = useState(false);
  const [checkD, setCheckD] = useState(false);
  const [accounts, setViewAccount] = useState([]);
  const [locations, setLocations] = useState([]);
  const [charges, setCharges] = useState({})
  const [modal, setModal] = useState(false);
  const [filter, setFilter] = useState("")

  const toggle = () => setModal(!modal);

  function onFilter(e) {
    viewAccounts(e.target.value)
  }

  function addStore() {
    //setCheck(true);
    toggle();
  }

  async function add() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    setCheck(true);
    const data = { ...form };
    try {
      const response = await Axios.post(`${BASE_URL}/merchants/address`, data, {
        headers,
      });
      if (response.data.hasError) {
        console.log(response.data);
        swal(response.data.errors.message,"","error");
      } else {
        swal(response.data.message, "", "success");
        setCheck(false);
        toggle();
        viewLocation()
      }
    } catch (error) {
      console.log(error);
    }
  }

  function deleteLocation(id) {
    setCheck(true);
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Address!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        remove(id);
        // swal("Poof! Your imaginary file has been deleted!", {
        //   icon: "success",
        // });
      } else {
       // swal("Your imaginary file is safe!");
      }
    });
  }

  async function remove(id) {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.delete(`${BASE_URL}/merchants/address/1585139305/${id}`, {
        headers,
      });
      if (response.data.hasError) {
        console.log(response.data);
        swal(response.data.errors.message,"","error");
      } else {
        swal(response.data.message, "", "success");
        viewLocation()
        setCheck(false);
      }
    } catch (error) {
      console.log(error);
    }
  }


  async function addCharge(e) {
    e.preventDefault();
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await Axios.put(`${BASE_URL}/admin/charges`, form, {
        headers,
      });
      if (response.data.hasError) {
        swal(response.data.errors.message, "", "error");
      } else {
        swal(response.data.message, "", "success");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function viewAccounts(status) {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.get(
        `${BASE_URL}/transaction/admin/accounts?approved=${status || 'false'}`,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        setViewAccount(response.data.data.accounts);
        console.log(response.data.data.accounts);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function viewLocation() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.get(
        `${BASE_URL}/merchants/address/1585139305`,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        setLocations(response.data.data.store_address);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function viewCharges() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.get(
        `${BASE_URL}/admin/charges`,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        setCharges(response.data.data.charges);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function addDelivery(e) {
    e.preventDefault();
    setCheckD(true)
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = {delivery_cost: form.delivery}

    try {
      const response = await Axios.put(`${BASE_URL}/merchants/store/edit/1585139305`, data, {
        headers,
      });
      if (response.data.hasError) {
        swal(response.data.errors.message, "", "error");
        setCheckD(false)
      } else {
        swal(response.data.message, "", "success");
        setCheckD(false)
        viewStore();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function approveAccount(id) {
    //setCheck(true)
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const data = {account_id: id, approved: true}

    try {
      const response = await Axios.put(`${BASE_URL}/transaction/admin/accounts`, data, {
        headers,
      });
      if (response.data.hasError) {
        swal(response.data.errors.message, "", "error");
        //setCheck(false)
      } else {
        swal(response.data.message, "", "success");
        //setCheck(false)
        viewAccounts();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function viewStore() {
    const token = Auth.getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await Axios.get(
        `${BASE_URL}/merchants/store/view/1585139305`,
        { headers }
      );
      if (response.data.hasError) {
        console.log(response.data);
      } else {
        setStore(response.data.data.store);
        console.log(response.data.data.store);
      }
    } catch (error) {
      console.log(error);
    }
  }

  

  useEffect(() => {
    viewAccounts();
    viewLocation();
    viewCharges();
    viewStore()
  }, []);

  return (
    <Layout>
      <section className="dash-section ">
        <div className="col-md-4 pb-3">
          <InputGroup>
            <Input className="search-admin" />
            <InputGroupAddon addonType="append">
              <Button className="search-btn">Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <h3 className="pt-3 pb-3">Settings</h3>

        <div>
          <form className="form" onSubmit={addCharge}>
            <div className="p-3">
              <div className="">
                <div className="form-group mb-2">
                  <label htmlFor="">Charges (current charge : {charges.charge+'%'})</label>
                  <input
                    type="number"
                    name="item"
                    className="form-control mr-3"
                    value={form.charge}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        charge: parseInt(e.target.value),
                      })
                    }
                    required
                    placeholder={charges.charge}
                  />
                </div>
              </div>

              <div className="">
                <div className="form-group mb-2">
                  <label htmlFor="">Max Amount (current amount : {charges.max})</label>
                  <input
                    type="number"
                    name="item"
                    className="form-control mr-3"
                    value={form.max}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        max: parseInt(e.target.value),
                      })
                    }
                    placeholder={charges.max}
                    required
                  />
                </div>
              </div>

              <button type="submit" className="btn btn-outline-secondary mb-4">
                {check ? "updating" : "update charges"}
              </button>

              <div className="">
                <div className="form-group mb-2">
                  <label htmlFor="">Delivery Fee (current fee : {store.delivery_cost})</label>
                  <input
                    type="number"
                    name="item"
                    className="form-control mr-3"
                    value={form.delivery}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        delivery: parseInt(e.target.value),
                      })
                    }
                    placeholder={store.delivery_cost}
                    required
                  />
                </div>
              </div>

              <button type="button" onClick={addDelivery} className="btn btn-outline-secondary">
                {checkD ? "updating" : "update delivery fee"}
              </button>

              
            </div>
          </form>
        </div>
        <br />
        <div className="d-flex justify-content-between">
          <h5>Store Locations</h5>
          <button
            onClick={addStore}
            className="btn btn-sm btn-success"
          >
            add store location
          </button>
        </div>

        <div className="table-responsive ">
          <table className="table table-borderless table-striped table-hover ">
            <thead>
              <tr>
                <th>Store id</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Address 1</th>
                <th>Address 2</th>
                <th>City</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {(locations || []).map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.store_id}</td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>{item.line1}</td>
                    <td>{item.line2}</td>
                    <td>{item.city}</td>
                    <td>{dayjs(item.createdAt).format("YYYY-MM-DD")}</td>
                    <td>
                      <button
                        onClick={() => deleteLocation(item.id)}
                        className="btn btn-sm btn-danger"
                      >
                        delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <br />
        <h5>Merchant account</h5>
        <br/>
        <div className="input-group mb-3 col-md-3">
          <select
            className="custom-select"
            id="inputGroupSelect01"
            //value={form.filter}
            onChange={onFilter}
          >
            <option selected value="false">Not Approved</option>
            <option value="true">Approved</option>
          </select>
        </div>
        <div className="table-responsive ">
          <table className="table table-borderless table-striped table-hover ">
            <thead>
              <tr>
                <th>Store id</th>
                <th>Name</th>
                <th>Acct No</th>
                <th>Bank</th>
                <th>BVN</th>
                <th>Status</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {(accounts || []).map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.store_id}</td>
                    <td>{item.account_name}</td>
                    <td>{item.account_number}</td>
                    <td>{truncate(item.bank_name, 30)}</td>
                    <td>{item.BVN}</td>
                    <td>
                      {item.approved ? (
                        <span className="badge badge-success">VALID</span>
                      ) : (
                        <span className="badge badge-danger">NOT VALID</span>
                      )}
                    </td>
                    <td>{dayjs(item.createdAt).format("YYYY-MM-DD")}</td>
                    <td>
                      <button
                        disabled={item.approved}
                        onClick={() => approveAccount(item.id)}
                        className="btn btn-sm btn-success"
                      >
                        approve
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>


        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Add Tax</ModalHeader>
          <ModalBody>
            <div className="form-group mb-2">
              <label htmlFor="">Name</label>
              <input
                type="text"
                name="item"
                className="form-control mr-3"
                placeholder=""
                onChange={(e) =>
                  setForm({
                    ...form,
                    name: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="">Email</label>
              <input
                type="email"
                name="item"
                className="form-control mr-3"
                placeholder=""
                onChange={(e) =>
                  setForm({
                    ...form,
                    email: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="">Phone</label>
              <input
                type="number"
                name="item"
                className="form-control mr-3"
                placeholder=""
                onChange={(e) =>
                  setForm({
                    ...form,
                    phone: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="">Address 1</label>
              <input
                type="text"
                name="item"
                className="form-control mr-3"
                placeholder=""
                onChange={(e) =>
                  setForm({
                    ...form,
                    line1: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="">Address 2</label>
              <input
                type="text"
                name="item"
                className="form-control mr-3"
                placeholder=""
                onChange={(e) =>
                  setForm({
                    ...form,
                    line2: e.target.value,
                  })
                }
                required
              />
              <div className="form-group mb-2">
              <label htmlFor="">City</label>
              <input
                type="text"
                name="item"
                className="form-control mr-3"
                placeholder=""
                onChange={(e) =>
                  setForm({
                    ...form,
                    city: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="">Postal</label>
              <input
                type="number"
                name="item"
                className="form-control mr-3"
                placeholder=""
                onChange={(e) =>
                  setForm({
                    ...form,
                    postal: e.target.value,
                  })
                }
                required
              />
            </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button color="primary" onClick={add}>
              Add
            </Button>
          </ModalFooter>
        </Modal>
      </section>
    </Layout>
  );
};

Settings.propTypes = {
  coupon: PropTypes.object.isRequired,
  fetchCoupons: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({});

const pageActions = {};

export default connect(mapStateToProps, pageActions)(Settings);
