import {
  FETCH_WITHDRAWAL,
  FETCH_WITHDRAWAL_SUCCESS,
  FETCH_WITHDRAWAL_FAILED
} from '../../types';

const initialState = {
  withdrawal: {},
  isLoading: false,
  errors: {},
  error: false
}

const fetchWithdrawalReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_WITHDRAWAL:
      return {
        ...state,
        isLoading: true,
          withdrawal: action.payload
      }
      case FETCH_WITHDRAWAL_SUCCESS:
        return {
          ...state,
          isLoading: false,
            withdrawal: action.payload
        }
        case FETCH_WITHDRAWAL_FAILED:
          return {
            ...state,
            isLoading: false,
              errors: action.payload,
              error: true
          }
          default:
            return {
              ...state
            };
  }
}

export default fetchWithdrawalReducer;
