import {
  FETCH_MEDIA,
  FETCH_MEDIA_SUCCESS,
  FETCH_MEDIA_FAILED
} from '../../types';

const initialState = {
  media: [],
  isLoading: false,
  errors: {},
  error: false
}

const fetchMediaReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_MEDIA:
      return {
        ...state,
        isLoading: true,
          media: action.payload
      }
      case FETCH_MEDIA_SUCCESS:
        return {
          ...state,
          isLoading: false,
            media: action.payload
        }
        case FETCH_MEDIA_FAILED:
          return {
            ...state,
            isLoading: false,
              errors: action.payload,
              error: true
          }
          default:
            return {
              ...state
            };
  }
}

export default fetchMediaReducer;
