import axios from 'axios';
import {
  FETCH_AUCTIONS,
  FETCH_AUCTIONS_SUCCESS,
  FETCH_AUCTIONS_FAILED,
  FETCH_AUCTION_SUCCESS,
  FETCH_AUCTION_FAILED,
  FETCH_AUCTION_BID_SUCCESS,
  FETCH_AUCTION_BID_FAILED,
  FETCH_BIDS_SUCCESS,
  FETCH_BIDS_FAILED
} from '../types';
import {
  BASE_URL
} from '../constant'
import Auth from '../../config/auth'

const token = Auth.getToken();
const headers = {
  Authorization: `Bearer ${token}`
};


export const fetchAuctions = (count) => dispatch => {
  var pageCount = 12 + (count ? count : 0);

  axios.get(`${BASE_URL}/auctions?store_url=shobbu&pages=1&paginate=${pageCount}`, token != null && ({
      headers
    }))
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_AUCTIONS_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_AUCTIONS_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_AUCTIONS_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchAuction = (id) => dispatch => {

  axios.get(`${BASE_URL}/bids/${id}`, token != null && ({
      headers
    }))
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_AUCTION_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_AUCTION_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_AUCTION_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchPostAuction = (data) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.post(`${BASE_URL}/auctions/create/1585139305`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_AUCTION_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_AUCTION_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_AUCTION_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchDeclareWinner = (id) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.put(`${BASE_URL}/auctions/winner/${id}`, {}, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_AUCTION_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_AUCTION_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_AUCTION_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}

export const fetchPostBid = (id, data) => dispatch => {

  axios.post(`${BASE_URL}/bids/${id}`, data, {
      headers
    })
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_AUCTION_BID_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_AUCTION_BID_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_AUCTION_BID_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}


export const fetchBids = (id) => dispatch => {
  const token = Auth.getToken();
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios.get(`${BASE_URL}/bids/mybids/all`, token != null && ({
      headers
    }))
    .then(response => {
      console.log(response)
      if (!response.data.hasError) {
        dispatch({
          type: FETCH_BIDS_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: FETCH_BIDS_FAILED,
          payload: response.data
        })
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_BIDS_FAILED,
        payload: {
          hasError: true,
          errors: {
            message: "network error",
            error: err.data
          }
        }
      })
    })
}
